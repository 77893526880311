import React from "react";
import { CircleSpinner } from "react-spinners-kit";

import { Button } from "@mui/material";

import ButtonStyles from "./ButtonStyles";

export default function ButtonCustom({
  onClick = () => {},
  children,
  type = "primary",
  isDisabled = false,
  submit = false,
  noCapital = false,
  promoteBtnCls = "",
  customClassName = "",
  className = "",
  isSubmitting = false,
}) {
  return (
    <ButtonStyles className={className} type={type} noCapital={noCapital}>
      <Button
        className={
          type === "primary"
            ? "btn btn-active " + customClassName
            : type === "rounded"
            ? "btn rounded-btn " +
              (promoteBtnCls && promoteBtnCls.length > 0 ? promoteBtnCls : "")
            : type === "dark"
            ? "btn btn-dark"
            : type === "white"
            ? "btn btn-white"
            : "btn btn-negative " + customClassName
        }
        variant={"contained"}
        onClick={onClick}
        disabled={isDisabled ? isDisabled : isSubmitting}
        {...(submit ? { type: "submit" } : {})}
      >
        {children}
        {isSubmitting && (
          <span className="button-spin">
            <CircleSpinner
              color={
                type === "primary" || type === "rounded" || type === "dark"
                  ? "#FFF"
                  : "#000C3D"
              }
              size={20}
            />
          </span>
        )}
      </Button>
    </ButtonStyles>
  );
}
