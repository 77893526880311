import axios from "axios";

import config from "../config";
import { applyAccessibilityHelp, clearLocalStorage } from "../helper/helper";

let axiosInstance = axios.create({
  baseURL: config().API_URL, // API URL
});

axiosInstance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
axiosInstance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosInstance.interceptors.response.use(
  function (response) {
    // console.log("response", response);
    return response;
  },
  function (error) {
    //console.log("--------------------------", error);
    const responseData = error.response
      ? error.response.data
      : {
          code: 0,
          message: error.message,
        };
    // console.log("error", error.response);

    const statusData = error.response ? error.response.status : 500;
    if (statusData === 401) {
      // console.log("clear localstorage2");
      sessionStorage.clear();
      clearLocalStorage();
      applyAccessibilityHelp(false);

      // navigate(routes.LOGIN);
      //redirectTo(routes.LOGIN);
    }

    return Promise.reject({
      status: statusData || 500,
      data: responseData,
    });
  }
);

export default axiosInstance;
