import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import {
  findIndex,
  where,
  isEmpty,
  isUndefined,
  each,
  filter,
  find,
} from "underscore";

import CheckBox from "../Checkbox/Checkbox";
import Textbox from "../Textbox/Textbox";

import DropArrow from "../../assets/svg/chevArrowDown.svg";

const CustomDropdown = ({
  data,
  displayLabel,
  displaySchoolLabel,
  displayValue,
  placeholder,
  noOption = "No options to display",
  noSelectionText = "Select any",
  selectAllText = "All options selected",
  error = false,
  errorText,
  onBlur = () => {},
  className,
  dropdownClassName = "",
  name = "dropdown-textbox",
  value,
  isMulti = false,
  isSchoolLabel = false,
  isSuperAdmin = false,
  displayCustomSchoolOption = false,
  seeLessPreviewLength = 5, // Max allowed length of preview cards in see less mode
  seeMorePreviewLength = 100, // Max allowed length of preview cards in see more mode
  ...props
}) => {
  let [getvalue, setValue] = useState(value ? value : []);
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [text, setText] = useState("");
  const [listPreviewLength, setListPreviewLength] =
    useState(seeLessPreviewLength);

  const handleDelete = (index, val) => {
    const list = getvalue;

    list.splice(index, 1);
    setValue([...list]);

    props.onClick(list);
    const request = listingData;
    const existIndex = findIndex(request, {
      [displayValue]: val[displayValue],
    });
    if (existIndex >= 0) {
      request[existIndex]["checked"] = false;
    }

    const totalCheckedData = where(request, { checked: true });

    if (totalCheckedData.length === listingData.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setListingData([...request]);
  };

  const closeDropdown = (e) => {
    if (open === true) {
      setOpen(false);
    }
  };

  const isInitialOpen = useRef(true);
  useEffect(() => {
    if (isInitialOpen.current) {
      isInitialOpen.current = false;
    } else {
      if (!open) {
        onBlur();
        setText("");
        setShortListData("");
      }
    }

    window.addEventListener("click", closeDropdown);
    return () => window.removeEventListener("click", closeDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (
      isMulti &&
      value &&
      (isEmpty(value) || isUndefined(value)) &&
      data &&
      data.length > 0
    ) {
      setListingData([
        ...each(data, function (l_data) {
          l_data.checked = false;
        }),
      ]);
    } else {
      setListingData(data);
    }

    setMainData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setListingData(data);
    setMainData(data);
  }, [data]);

  useEffect(() => {
    if (
      value &&
      (!isEmpty(value) || !isUndefined(value)) &&
      props.UpdateCheck
    ) {
      const unCheckedData = where(value, { checked: false });
      if (unCheckedData?.length === value?.length) {
        setValue([]);

        if (
          value &&
          value.length > 0 &&
          isMulti &&
          listingData &&
          listingData.length
        ) {
          const request = listingData;
          each(value, function (dat) {
            const existindex = findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = false;
            }
          });

          setListingData([...request]);
        }
      } else {
        setValue(value);

        if (
          value &&
          value.length > 0 &&
          isMulti &&
          listingData &&
          listingData.length
        ) {
          const request = listingData;
          each(value, function (dat) {
            const existindex = findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = true;
            }
          });

          setListingData([...request]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.UpdateCheck]);

  useEffect(() => {
    if (value && (!isEmpty(value) || !isUndefined(value))) {
      const unCheckedData = where(value, { checked: false });
      if (unCheckedData?.length === value?.length) {
        setValue([]);
        if (
          value &&
          value.length > 0 &&
          isMulti &&
          listingData &&
          listingData.length
        ) {
          const request = listingData;
          each(value, function (dat) {
            const existindex = findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = false;
            }
          });

          setListingData([...request]);
        }
      } else {
        setValue(value);
        if (
          value &&
          value.length > 0 &&
          isMulti &&
          listingData &&
          listingData.length
        ) {
          const request = listingData;
          each(value, function (dat) {
            const existindex = findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = true;
            }
          });

          setListingData([...request]);
        }
      }
    } else if (isMulti) setValue([]);
    else setValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectedValues = (e, val, index) => {
    const getvalueData = getvalue ?? [];
    const existIndex = findIndex(getvalueData ?? [], {
      [displayValue]: val[displayValue],
    });

    const request = listingData;
    request[index]["checked"] =
      existIndex !== -1 ? false : !request[index]["checked"];

    const totalCheckedData = where(request, { checked: true });

    if (totalCheckedData?.length === listingData?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setListingData([...request]);

    if (Boolean(request[index]["checked"]) === true) {
      getvalueData.push(val);
    } else {
      getvalueData.splice(existIndex, 1);
    }

    setValue([...getvalueData]);

    props.onClick(getvalue);
  };

  const SelectAll = () => {
    const setData = [];

    setAllChecked(!allChecked);

    each(listingData, function (l_data) {
      l_data.checked = !allChecked;
      setData.push(l_data);
    });

    if (!allChecked) {
      setValue([...setData]);
      props.onClick(setData);
    } else {
      //getvalue=[];
      setValue([]);
      props.onClick([]);
    }

    setListingData([...listingData]);
  };

  const setShortListData = (searchParams = "") => {
    searchParams = searchParams.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    const reg = new RegExp(searchParams.toLowerCase());
    const existData = filter(mainData, (l_data = {}) => {
      const res = (
        l_data[displayLabel] && l_data[displayLabel] !== null
          ? l_data[displayLabel]
          : ""
      )
        .toString()
        .toLowerCase()
        .match(reg);
      if (res && res.length) {
        return l_data;
      }
    });

    setListingData(existData);

    if (getvalue?.length === existData?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const closeOpenDropdowns = (event) => {
    let opendds = document.querySelectorAll(".dropdown-wrapper");

    opendds.forEach(function (menus) {
      menus.click();
    });
  };

  const multiSelectOption = (val, index) => {
    const getOptionFromValue = isEmpty(getvalue)
      ? false
      : find(getvalue, { [displayValue]: val[displayValue] }, {});
    const isSelected =
      (getOptionFromValue !== false &&
        !isEmpty(getOptionFromValue) &&
        getOptionFromValue.checked) ||
      val.checked;

    return (
      <li
        key={index}
        className={isSelected ? "active cursor-pointer" : "cursor-pointer"}
      >
        <CheckBox
          onClick={(e) => selectedValues(e, val, index)}
          value={val[displayValue]}
          isChecked={isSelected ? true : false}
        />
        {val[displayLabel]}
        {isSchoolLabel && isSuperAdmin && val[displaySchoolLabel]
          ? ` (${val[displaySchoolLabel]})`
          : ""}
      </li>
    );
  };

  return (
    <>
      {isMulti ? (
        <div
          className={
            open ? "dropdown-wrapper multili open" : "dropdown-wrapper"
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="custom-dropdowninput">
            <Textbox
              name={name}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                setShortListData(e.target.value);
              }}
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              placeholder={placeholder}
              error={error}
              errorText={errorText}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <span
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <img src={DropArrow} alt="arrow" />
            </span>
          </div>
          <div className="customscroll-div custom-dropdownul">
            <ul
              className={
                listingData.length > 0 ? "select-all-option" : "no-option"
              }
            >
              {listingData.length > 0 ? (
                <li
                  className={
                    allChecked ? "active cursor-pointer" : "cursor-pointer"
                  }
                >
                  <CheckBox
                    value={
                      allChecked ||
                      (getvalue.length > 0 &&
                        getvalue.length === listingData.length)
                    }
                    isChecked={
                      allChecked ||
                      (getvalue.length > 0 &&
                        getvalue.length === listingData.length)
                    }
                    onClick={() => SelectAll()}
                  />
                  Select all
                </li>
              ) : (
                <li>No options to display</li>
              )}
            </ul>

            {listingData.length <= 10 ? (
              <Scrollbars autoHeight>
                <div>
                  <ul
                    className={
                      isSuperAdmin && isSchoolLabel ? "school_name_align" : ""
                    }
                  >
                    {listingData.map((val, index) =>
                      multiSelectOption(val, index)
                    )}
                  </ul>
                </div>
              </Scrollbars>
            ) : (
              <Scrollbars autoHeight>
                <div>
                  <ul
                    className={
                      isSuperAdmin && isSchoolLabel ? "school_name_align" : ""
                    }
                  >
                    {listingData.map((val, index) =>
                      multiSelectOption(val, index)
                    )}
                  </ul>
                </div>
              </Scrollbars>
            )}
          </div>
          <div className="dropdown-array-option">
            {getvalue.length > 0 && getvalue.length === mainData.length ? (
              <Chip
                label={selectAllText}
                onDelete={() => SelectAll()}
                color="primary"
                variant="outlined"
                deleteIcon={<CloseIcon />}
              />
            ) : (
              <>
                {getvalue?.length > 0 &&
                  getvalue.slice(0, listPreviewLength).map((values, index) => (
                    <Chip
                      key={index}
                      // icon={icon}
                      label={
                        values[displayLabel] +
                        (isSchoolLabel && isSuperAdmin
                          ? ` (${values[displaySchoolLabel]})`
                          : "")
                      }
                      // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                      onDelete={() => handleDelete(index, values)}
                      color="primary"
                      variant="outlined"
                      deleteIcon={<CloseIcon />}
                    />
                  ))}

                {getvalue?.length > seeLessPreviewLength && (
                  <div className="dropdown-array-option__see-more-less">
                    {listPreviewLength === seeLessPreviewLength ? (
                      <Chip
                        label="See More"
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          setListPreviewLength(seeMorePreviewLength)
                        }
                      />
                    ) : (
                      <Chip
                        label="See Less"
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          setListPreviewLength(seeLessPreviewLength)
                        }
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={open ? "dropdown-wrapper open" : "dropdown-wrapper"}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className={`custom-dropdowninput ${className}`}>
            <Textbox
              name={name}
              value={getvalue ? getvalue[displayLabel] : text ? text : ""}
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              onChange={(e) => {
                if (getvalue) {
                  setValue();
                  props.onClick({});
                }
                setText(e.target.value);
                setShortListData(e.target.value);
              }}
              placeholder={placeholder}
              error={error}
              errorText={errorText}
              // onBlur={onBlur}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <span
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <img src={DropArrow} alt="Dropdown" />
            </span>
          </div>
          <div
            className={`${dropdownClassName} customscroll-div custom-dropdownul`}
          >
            {listingData.length <= 10 ? (
              <Scrollbars autoHeight>
                <div>
                  <ul>
                    {!listingData?.length ? (
                      <li
                        className={
                          !getvalue ? "active cursor-pointer" : "cursor-pointer"
                        }
                        onClick={() => {
                          setText("");
                          setShortListData("");
                          setValue();
                          props.onClick({});
                        }}
                      >
                        {noOption}
                      </li>
                    ) : (
                      <li
                        className={
                          !getvalue ? "active cursor-pointer" : "cursor-pointer"
                        }
                        onClick={() => {
                          setText("");
                          setShortListData("");
                          setValue();
                          props.onClick({});
                        }}
                      >
                        {noSelectionText}
                      </li>
                    )}

                    {listingData &&
                      listingData.length > 0 &&
                      listingData.map((val, index) => (
                        <li
                          key={index}
                          className={
                            getvalue?.[displayLabel] === val?.[displayLabel]
                              ? "active cursor-pointer"
                              : "cursor-pointer"
                          }
                          onClick={(e) => {
                            setValue(val);
                            props.onClick(val);
                          }}
                        >
                          {displayCustomSchoolOption &&
                          val.displayCustomSchoolOption ? (
                            <b>{val[displayLabel]}</b>
                          ) : (
                            <>{val[displayLabel]}</>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </Scrollbars>
            ) : (
              <Scrollbars autoHeight>
                <div>
                  <ul>
                    <li
                      className={
                        !getvalue ? "active cursor-pointer" : "cursor-pointer"
                      }
                      onClick={() => {
                        setValue();
                        props.onClick({});
                        setOpen(!open);
                      }}
                    >
                      {noSelectionText}
                    </li>
                    {listingData &&
                      listingData.length > 0 &&
                      listingData.map((val, index) => (
                        <li
                          key={index}
                          className={
                            getvalue?.[displayLabel] === val?.[displayLabel]
                              ? "active cursor-pointer"
                              : "cursor-pointer"
                          }
                          onClick={(e) => {
                            setValue(val);
                            props.onClick(val);
                            setOpen(!open);
                          }}
                        >
                          {displayCustomSchoolOption &&
                          val.displayCustomSchoolOption ? (
                            <b>{val[displayLabel]}</b>
                          ) : (
                            <>{val[displayLabel]}</>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </Scrollbars>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomDropdown;
