import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const sendFeedBackAPICareer = async (data) => {
  return axiosInstance
    .post(`/send_feedbacks`, data, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const sendFeedBackAPIAdmission = async (data) => {
  return axiosInstance
    .post(`/admission_portal/admin/send_feedbacks`, data, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const contactUsAPI = async (data) => {
  return axiosInstance
    .post(`/contact_us`, data, headers_with_token())
    .then((res) => {
      return res.data;
    });
};
