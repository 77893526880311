import { isNull } from "underscore";
import { ApiWrapper, REQUEST } from ".";

import config from "../config";
import { createQueryStringURL } from "../utils/common";

const API_URL = config().API_URL;
const BASE_URL = `${API_URL}`;

/**
 * Fetch all chats list
 * @returns
 */
export const fetchChatsList = async (portal = "admission") => {
  return await ApiWrapper({
    url:
      portal === "admission"
        ? `${BASE_URL}admission_portal/admin/network_modules/list_messages`
        : `${BASE_URL}admin/network_modules/list_messages`,
  });
};

/**
 * Fetch conversation
 * @param {*} conversationId
 * @returns
 */
export const fetchConversation = async (
  conversationId,
  options = {},
  portal = "admission"
) => {
  let link =
    portal === "admission"
      ? `${BASE_URL}admission_portal/admin/network_modules/view_messages`
      : `${BASE_URL}network_modules/view_messages`;
  const queryStringURL = createQueryStringURL(link, options);
  return await ApiWrapper({
    url:
      Object.keys(options).length > 0
        ? `${queryStringURL}&conversation_id=${conversationId}`
        : `${link}?conversation_id=${conversationId}`,
  });
};

export const fetchContactsList = async (options = {}, portal = "admission") => {
  let link =
    portal === "admission"
      ? "admission_portal/admin/network_modules"
      : "admin/network_modules";
  const queryString = Object.keys(options)
    .map((option) =>
      !options[option] || isNull(options[option])
        ? `${option}=`
        : `${option}=${options[option]}`
    )
    .join("&");
  link = Object.keys(options).length > 0 ? `${link}?${queryString}` : link;
  return await ApiWrapper({
    url: `${API_URL}${link}`,
  });
};

/**
 * Get all filters for dropdown
 * @returns
 */
export const fetchDiscoverNetworkFiltersAdmissions = async (
  portal = "admission"
) => {
  return await ApiWrapper({
    url:
      portal === "admission"
        ? `${API_URL}admission_portal/admin/network_modules/discover_search_list`
        : `${API_URL}network_modules/discover_search_list`,
  });
};

/**
 * Remove conversation/chat
 * @param {*} conversationId
 * @returns
 */
export const deleteChat = async (conversationId, portal = "admission") => {
  return await ApiWrapper({
    url:
      portal === "admission"
        ? `admission_portal/admin/network_modules/leave_or_delete_chat?conversation_id=${conversationId}`
        : `/admin/network_modules/leave_or_delete_chat?conversation_id=${conversationId}`,
  });
};

/**
 * Report chat
 * @param {Number} conversationId
 * @param {String} reportText
 * @param {String} portal Options: "admission" or "career"
 * @returns ApiWrapper
 */
export const reportChat = async (
  conversationId,
  reportText,
  portal = "career"
) => {
  // Prepare form data
  let formData = new FormData();
  formData.append("conversation_id", conversationId);
  formData.append("report_text", reportText);

  return await ApiWrapper({
    url:
      portal === "admission"
        ? "admission_portal/admin/network_modules/report_chat"
        : "admin/network_modules/report_chat",
    method: REQUEST.POST,
    data: formData,
  });
};

/**
 * Mute or Unmute chat
 * @param {Number} conversationId
 * @param {String} portal Options: "admission" or "career"
 * @returns ApiWrapper
 */
export const muteOrUnmuteChat = async (
  conversationId,
  portal = "admission"
) => {
  return await ApiWrapper({
    url:
      portal === "admission"
        ? `admission_portal/admin/network_modules/mute_chat?conversation_id=${conversationId}`
        : `admin/network_modules/mute_chat?conversation_id=${conversationId}`,
  });
};

/**
 * Request a meeting
 * @param {*} receiverId
 * @returns
 */
export const requestMeeting = async (data) => {
  return await ApiWrapper({
    url: `admission_portal/network_modules/request_meeting`,
    method: REQUEST.POST,
    data,
  });
};

/**
 * Search Conversation
 * @param {*} search
 * @returns
 */
export const searchConversation = async (
  search,
  page = 1,
  portal = "admission"
) => {
  return await ApiWrapper({
    url:
      portal === "admission"
        ? `admission_portal/admin/network_modules/search_messages?search=${search}&page=${page}`
        : `/admin/network_modules/search_messages?search=${search}&page=${page}`,
  });
};

/**
 * Get monitor chat all messages
 */
export const monitorListMessages = async (school_id) => {
  return await ApiWrapper({
    url: `admission_portal/admin/network_modules/monitor_list_messages?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

/**
 * Get monitor chat unread messages
 */
export const monitorUnReadListMessages = async (school_id) => {
  return await ApiWrapper({
    url: `admission_portal/admin/network_modules/admission_unread_chat_monitor?school_id=${school_id}`,
    method: REQUEST.GET,
  });
};

/**
 * Delete monitor ambassador chats
 */
export const deleteMonitorAmbassadorChats = async (conversationIds = []) => {
  let queryString = "";
  conversationIds.forEach((id, index) => {
    queryString += `${index === 0 ? "?" : "&"}conversation_ids[]=${id}`;
  });
  return await ApiWrapper({
    url: `admission_portal/admin/network_modules/monitor_delete_bulk_chat${queryString}`,
    method: REQUEST.DELETE,
  });
};

/**
 * Delete monitor single prospect chats
 */
export const deleteMonitorSingleProspectChat = async (conversation_id) => {
  return await ApiWrapper({
    url: `admission_portal/admin/network_modules/monitor_delete_single_chat/${conversation_id}`,
    method: REQUEST.DELETE,
  });
};

/**
 * Assign personal chat to different user
 */
export const assignChatAdmissions = async ({
  conversation_window_id = null,
  assignee_id = null,
}) => {
  let formData = new FormData();
  formData.append("conversation_window_id", conversation_window_id);
  formData.append("assignee_id", assignee_id);

  return await ApiWrapper({
    url: "admission_portal/admin/network_modules/assign_chat",
    method: REQUEST.POST,
    data: formData,
  });
};
