import React, { useEffect, useState } from "react";

import ButtonCustom from "../../components/Button/Button";

const AddFormFooter = (props) => {
  const {
    dynamicFormSteps = false,
    stepType,
    setStepType,
    stepTypeProps,
    totalStepCount,
    disableActions = false,
    saveButtonText = "Save",
    extraButtonText = false,
    hideBackOption = false,
    hideCancelButton = false,
    displaySaveButton = true,
    displaySteps = true,
    saveButton,
    extraButton,
  } = props;

  const [step, setStep] = useState(props.step);

  useEffect(
    () => {
      setStep(props.step);
    },
    [props.step],
    [props.setSteepingFn]
  );

  useEffect(() => {
    // If dynamicFormSteps then allow add form to set step type else set it from here form the props specified in route
    if (!dynamicFormSteps) setStepType(stepTypeProps);
  }, [dynamicFormSteps, stepTypeProps, setStepType]);

  return (
    <>
      {(!stepType || step === 1) && !hideBackOption && !hideCancelButton ? (
        <ButtonCustom
          onClick={() => {
            props.cancelButtonFn();
          }}
          isDisabled={disableActions}
          type="secondary"
        >
          Cancel
        </ButtonCustom>
      ) : (
        ""
      )}

      {stepType ? (
        <>
          {step === totalStepCount ? (
            <>
              {!hideBackOption && (
                <ButtonCustom
                  onClick={() => {
                    setStep(step - 1);
                    props.setSteepingFn(step - 1);
                  }}
                  type="secondary"
                >
                  Back
                </ButtonCustom>
              )}

              {displaySteps && (
                <ButtonCustom
                  onClick={() => {
                    props.saveButtonFn();
                  }}
                  isDisabled={disableActions}
                  type="primary"
                  isSubmitting={saveButton}
                  submit
                >
                  {saveButtonText}
                </ButtonCustom>
              )}

              {extraButtonText && (
                <ButtonCustom
                  onClick={() => {
                    props.extraButtonFn();
                  }}
                  isDisabled={disableActions}
                  type="primary"
                  isSubmitting={extraButton}
                  submit
                >
                  {extraButtonText}
                </ButtonCustom>
              )}
            </>
          ) : (
            <>
              {step > 1 && (
                <ButtonCustom
                  onClick={() => {
                    setStep(step - 1);
                    props.setSteepingFn(step - 1);
                  }}
                  type="secondary"
                >
                  Back
                </ButtonCustom>
              )}

              {displaySteps && props.showSkip ? (
                <ButtonCustom
                  onClick={() => {
                    setStep(step + 1);
                    props.setSteepingFn(totalStepCount);
                  }}
                  type="secondary"
                >
                  Skip
                </ButtonCustom>
              ) : (
                ""
              )}

              <ButtonCustom
                onClick={() => {
                  setStep(step + 1);
                  props.setSteepingFn(step + 1);
                }}
                type="primary"
              >
                Next
              </ButtonCustom>
            </>
          )}
        </>
      ) : (
        <>
          {displaySaveButton ? (
            <ButtonCustom
              onClick={() => {
                props.saveButtonFn();
              }}
              isDisabled={disableActions}
              isSubmitting={saveButton}
              type="primary"
            >
              {saveButtonText}
            </ButtonCustom>
          ) : (
            <></>
          )}

          {extraButtonText && (
            <ButtonCustom
              onClick={() => {
                props.extraButtonFn();
              }}
              isDisabled={disableActions}
              isSubmitting={extraButton}
              type="primary"
              submit
            >
              {extraButtonText}
            </ButtonCustom>
          )}
        </>
      )}
    </>
  );
};
export default AddFormFooter;
