import React from "react";

import cls from "classnames";

import { GRAY_COLOR } from "../../styles/variables";
import TextboxCustomStyles from "./TextboxCustomStyles";
import TextboxStyles from "./TextboxStyles";

const TextboxCustom = ({
  onClick = (e) => {},
  onChange = (e) => {},
  onBlur = (e) => {},
  onKeyPress,
  onEnterKey,
  id,
  name,
  value,
  type = "text",
  placeholder,
  width = "100%",
  height = 60,
  label = "",
  lableaignment = "center",
  textcolor = GRAY_COLOR,
  isMultiline = false,
  marginBottom = 25,
  rows = 3,
  error = false,
  errorText = "",
  required = false,
  isDisable = false,
  maxLength = null,
  ...props
}) => {
  return (
    <TextboxStyles
      width={width}
      lableaignment={lableaignment}
      marginBottom={marginBottom}
    >
      {label && (
        <label
          style={{
            width: width,
            margin:
              lableaignment === "left"
                ? "0px 0px 8px 20px"
                : "0px 0px 8px 12px",
          }}
          className={cls("form-label textbox-label", "text-primary")}
        >
          {`${label}${required ? "*" : ""}`}
        </label>
      )}
      <TextboxCustomStyles
        lableaignment={lableaignment}
        textcolor={textcolor}
        className="custom-textbox form-control"
        name={name}
        id={id ? id : name}
        type={type}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        width={width}
        height={height}
        onClick={(e) => onClick(e)}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onKeyPress={
          onKeyPress || onEnterKey
            ? (e) => {
                if (onKeyPress) onKeyPress(e);
                else if (e.key === "Enter" && onEnterKey) onEnterKey();
              }
            : () => {}
        }
        multiline={isMultiline}
        rows={isMultiline ? rows : 0}
        error={error}
        helperText={errorText ? errorText : ""}
        disabled={isDisable}
        FormHelperTextProps={{
          role: "alert",
          "aria-live": "assertive",
        }}
        {...props}
        {...(maxLength !== null
          ? {
              inputProps: {
                maxLength,
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
              },
            }
          : {
              inputProps: {
                "aria-label": props["aria-label"]
                  ? props["aria-label"]
                  : placeholder,
              },
            })}
      />
    </TextboxStyles>
  );
};

export default TextboxCustom;
