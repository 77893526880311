import styled from "styled-components";

// import { PRIMARY_COLOR, GRAY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  color: #000485;
  .MuiDialogContent-root {
    margin: ${(props) => props.customStyle && "10px 0 40px 0 !important"};
  }
`;
export const PTag = styled.p`
  padding-left: 40px;
  padding-right: 40px;
`;
