import React from "react";

import LoaderStyles from "./LoaderStyles";

const Loader = ({className = ""}) => {
  return (
    <LoaderStyles>
      <div className={`${className} loader`}>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </LoaderStyles>
  );
};

export default Loader;
