import styled from "styled-components";

// import { PRIMARY_COLOR, GRAY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  button {
    background-color: ${(props) =>
      props.type === "primary" ? "#000485" : "#8094AB"};
    color: #fff;
  }

  .thumbnail-dropzone {
    margin-bottom: 30px;
    a {
      cursor: pointer;
      color: var(--primary-dark-color);
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      font-family: "TTCommons-Regular";
      border-bottom: 2px solid var(--primary-dark-color);
      display: inline-block;
      text-decoration: none;
      &:hover {
        color: var(--primary-dark-color);
      }
    }
  }
  //
`;
