import React, { useEffect } from "react";

import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CustomDropdown from "../CustomDropdown/Cutsom-Dropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaginationControlled(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(
    props.defaultLimit
      ? { value: props.defaultLimit, label: props.defaultLimit }
      : { value: 10, label: 10 },
  );

  useEffect(() => {
    if (props.page !== page && props.page) {
      setPage(props.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  const handleChange = (event, value) => {
    setPage(value);
    props.changePaginationFn(value, pageSize.value);

    const mainContentDiv = document.getElementById("main-content");
    if (mainContentDiv)
      mainContentDiv.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
  };

  if (props.hidePagination) return <></>;

  return (
    <div className={`${classes.root} table-pagination`}>
      <div className="pagination-dropdown">
        <span>Rows per page : </span>
        <CustomDropdown
          value={pageSize}
          noSelectionText={false}
          data={[
            ...(props.allowAllLabel ? [{ value: "All", label: "All" }] : []),
            ...(props.defaultLimit && props.defaultLimit === 5
              ? [{ value: 5, label: 5 }]
              : []),
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
          ]}
          onClick={(data) => {
            setPageSize(data);
            props.changePaginationFn(1, data.value);
          }}
          displayValue="value"
          displayLabel="label"
          placeholder=""
        />
      </div>
      <Pagination
        count={props.total_page}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
}

PaginationControlled.defaultProps = {
  allowAllLabel: true,
};
