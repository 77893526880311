import React from "react";
import { Link } from "react-router-dom";

import { Formik } from "formik";
import { clone } from "underscore";

import { commonApiFN } from "../../common/api";
import { ForgotPasswordApi } from "../../common/module_wise_api_url/onboarding";
import Button from "../../components/Button/Button";
import TextBox from "../../components/Textbox/Textbox";
import { toastify } from "../../helper/helper";

import BackArrow from "../../assets/svg/f-btn-arrow-gray.svg";
import { INTERSTRIDE_LOGO_WHITE } from "../../Constants";

const ForgotPassword = () => {
  const handleForgotPassword = async (values) => {
    let requestData = clone(ForgotPasswordApi);
    requestData.params = {
      email: values,
      admin: true,
    };
    const response = await commonApiFN(requestData);
    if (response.success) {
      if (response?.message) {
        toastify("error", response.message);
      }
    } else {
      if (response?.message) {
        toastify("error", response.message);
      }
    }
    return;
  };
  return (
    <section className="OnboardingWrapper">
      <div className="left-content">
        <div className="left-content-container">
          <div className="brand-name">
            <a href>
              <img src={INTERSTRIDE_LOGO_WHITE} alt="brand" />
            </a>
          </div>
          <div className="text-content">
            <h1>Attract, engage, and support international students</h1>
            <h4>An integrated platform to support international students and alumni at every step of the way, from admissions through graduation.</h4>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="right-content-container">
          <div className="forgot-password-section">
            <Link to="/" className="back-to-url link-focus">
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>Back</span>
            </Link>
            <h1 className="text-center mb-0">
              Forgot your password?
            </h1>
            <p className="text-paragraph">
              Enter your email below and we’ll send you a link to reset your
              password.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Please enter Email";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Please enter valid Email";
                }
                return errors;
              }}
              onSubmit={(values, actions) => handleForgotPassword(values.email)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !errors.email) {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="form-group">
                    <label> Enter your email* </label>
                    <TextBox
                      name="email"
                      placeholder="Enter your .edu email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorText={errors.email}
                      id="forgot-input"
                    />
                  </div>
                  <Button
                    type="primary"
                    className="forgot-button"
                    isSubmitting={isSubmitting}
                    customClassName="w-full mb-40"
                    onClick={(e) => {
                      if (!errors.email) {
                        return handleSubmit(e);
                      }
                      return;
                    }}
                  >
                    Reset Password
                  </Button>
                </form>
              )}
            </Formik>
            <div className="verify-text">
              <p className="mb-15">
                If you don’t see the email, please check your spam folder.
              </p>
              <p>Still having problems?</p>
              <p>
                Email us at{" "}
                <a
                  href={`mailto:contact@interstride.com`}
                  className="link-focus"
                >
                  contact@interstride.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
