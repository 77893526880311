// Homefeed Career - START
export const AddHomefeed = {
  url: "admin/user_posts",
  method: "POST",
};

export const GetPreviewPost = {
  url: "admin/user_posts/preview",
  method: "GET",
};

export const GetHomefeedData = {
  url: "admin/user_posts",
  method: "GET",
};

export const DeleteHomefeed = {
  url: "admin/user_posts/",
  method: "DELETE",
};

export const Archive_UnArchive_Homefeed = {
  url: "admin/user_posts/archive_unarchive_post",
  method: "PUT",
};

export const GetHomefeedArchiveList = {
  url: "admin/user_posts/archived_posts_listing",
  method: "GET",
};

export const SearchHomefeed = {
  url: "admin/user_posts?search=",
  method: "GET",
};

export const SearchArchivedHomefeed = {
  url: "admin/user_posts/archived_posts_listing?search=",
  method: "GET",
};

export const getHomefeedbyId = {
  url: "admin/user_posts/",
  method: "GET",
};

export const UpdateHomefeed = {
  url: "admin/user_posts/",
  method: "PATCH",
};

export const AddHomefeedAttachment = {
  url: "admin/user_posts/{user_post_id}/attach",
  method: "POST",
};

export const RemoveHomefeedAttachment = {
  url: "admin/user_posts/{user_post_id}/attachments/{attachment_id}",
  method: "DELETE",
};

export const UpdateHomefeedAttachmentThumbnail = {
  url: "admin/user_posts/{user_post_id}/attachments/{attachment_id}",
  method: "PATCH",
};

export const UpdateHomefeedStatus = {
  url: "admin/user_posts/{user_post_id}/upload_complete",
  method: "PUT",
};
// Homefeed Career - END

// Homefeed Admissions - START
export const GetHomefeedDataAdmissions = {
  url: "admission_portal/admin/user_posts",
  method: "GET",
};

export const GetHomefeedArchiveListAdmissions = {
  url: "admission_portal/admin/user_posts/archived_posts_listing",
  method: "GET",
};

export const AddHomefeedAdmissions = {
  url: "admission_portal/admin/user_posts",
  method: "POST",
};

export const UpdateHomefeedAdmissions = {
  url: "admission_portal/admin/user_posts/",
  method: "PATCH",
};

export const AddHomefeedAttachmentAdmissions = {
  url: "admission_portal/admin/user_posts/{user_post_id}/attach",
  method: "POST",
};

export const RemoveHomefeedAttachmentAdmissions = {
  url: "admission_portal/admin/user_posts/{user_post_id}/attachments/{attachment_id}",
  method: "DELETE",
};

export const UpdateHomefeedAttachmentThumbnailAdmissions = {
  url: "admission_portal/admin/user_posts/{user_post_id}/attachments/{attachment_id}",
  method: "PATCH",
};

export const UpdateHomefeedStatusAdmissions = {
  url: "admission_portal/admin/user_posts/{user_post_id}/upload_complete",
  method: "PUT",
};
// Homefeed Admissions - END

export const GetVideoPreviewPost = {
  url: "admin/videos/preview",
  method: "GET",
};

//add Webinar
export const AddWebinar = {
  url: "admin/webinars",
  method: "POST",
};
//add Webinar Admission
export const AddWebinarAdmission = {
  url: "admission_portal/admin/webinars",
  method: "POST",
};

export const UpdateWebinar = {
  url: "admin/webinars/",
  method: "PATCH",
};

export const UpdateWebinarAdmissions = {
  url: "admission_portal/admin/webinars/",
  method: "PATCH",
};

export const getWebinarById = {
  url: "admin/webinars/",
  method: "GET",
};
export const GetZoomDetails = {
  url: "admin/webinars/get_zoom_details",
  method: "GET",
};

export const AddCategoryWebinar = {
  url: "admin/webinar_categories",
  method: "POST",
};

export const AddCategoryWebinarAdmissions = {
  url: "admission_portal/admin/webinar_categories",
  method: "POST",
};

export const UpdateCategoryWebinar = {
  url: "admin/webinar_categories/",
  method: "PATCH",
};

export const DeleteCategoryWebinar = {
  url: "admin/webinar_categories/",
  method: "DELETE",
};

export const ArchieveUnArchieveCategoryWebinar = {
  url: "admin/webinar_categories/archive_unarchive_webinar_category",
  method: "PUT",
};

export const getCategoryWebinar = {
  url: "admin/webinar_categories",
  method: "GET",
};

export const getCategoryWebinarAdmission = {
  url: "admission_portal/admin/webinar_categories",
  method: "GET",
};

export const getWebinarTimezones = {
  url: "admin/webinars/get_rails_timezone",
  method: "GET",
};

//add category
export const addCategoryForStudentService = {
  url: "admin/deal_categories",
  method: "POST",
};

//add category - Admissions
export const addCategoryForStudentServiceAdmissions = {
  url: "admission_portal/admin/deal_categories",
  method: "POST",
};

// get category schools
export const GetCategorySchoolList = {
  url: "admin/deal_categories/deal_category_schools",
  method: "GET",
};

// get category schools for admissions
export const GetCategorySchoolListAdmissions = {
  url: "admission_portal/admin/deal_categories/deal_category_schools",
  method: "GET",
};

// get video category schools
export const GetVideoCategorySchoolList = {
  url: "admin/video_categories/video_category_schools",
  method: "GET",
};

export const GetVideoCategorySchoolListAdmissions = {
  url: "admission_portal/admin/video_categories/video_category_schools",
  method: "GET",
};

export const getCategoryForStudentService = {
  url: "admin/deal_categories/",
  method: "GET",
};

export const UpdateCtaegoryForStudentService = {
  url: "admin/deal_categories/",
  method: "PUT",
};

export const SortDealCategory = {
  url: "admin/deal_categories/sortable_deal_categories",
  method: "PUT",
};

export const getCategoryForWebinars = {
  url: "admin/webinar_categories/",
  method: "GET",
};

export const GetWebinar = {
  url: "admin/webinars",
  method: "GET",
};

export const GetWebinarAdmissions = {
  url: "admission_portal/admin/webinars",
  method: "GET",
};

export const GetArchiveWebinar = {
  url: "admin/webinars/archived_webinars_listing",
  method: "GET",
};
// http://localhost:3005//api/v1/admin/webinars/archived_webinars_listing

export const GetArchiveWebinarAdmissions = {
  url: "admission_portal/admin/webinars/archived_webinars_listing",
  method: "GET",
};

export const Archive_UnArchive_Webinar = {
  url: "admin/webinars/archive_unarchive_webinar",
  method: "PUT",
};

export const Archive_UnArchive_Webinar_Admission_Portal = {
  url: "admission_portal/admin/webinars/archive_unarchive_webinar",
  method: "PUT",
};

export const Custom_Rss_Token = {
  url: "admin/webinars/get_custom_rss_token",
  method: "GET",
};

export const Custom_Rss_Token_Admissions_Portal = {
  url: "admission_portal/admin/webinars/get_custom_rss_token",
  method: "GET",
};

export const Delete_Webinar = {
  url: "admin/webinars/",
  method: "DELETE",
};

export const Delete_Webinar_Admissions_Portal = {
  url: "admin/webinars/",
  method: "DELETE",
};

//add Resource

export const AddVideoCategory = {
  url: "admin/video_categories",
  method: "POST",
};

export const AddVideoCategoryAdmissions = {
  url: "admission_portal/admin/video_categories",
  method: "POST",
};

export const UpdateVideoCategory = {
  url: "admin/video_categories/",
  method: "PATCH",
};

export const DeleteVideoCategory = {
  url: "admin/video_categories/",
  method: "DELETE",
};

export const GetVideoCategoryByID = {
  url: "admin/video_categories/",
  method: "GET",
};

//get list of categories
export const GetVideoCategory = {
  url: "admin/video_categories",
  method: "GET",
};

export const GetVideoCategoryAdmission = {
  url: "admission_portal/admin/video_categories",
  method: "GET",
};

export const ArchiveUnarchiveVideoCategory = {
  url: "admin/video_categories",
  method: "PUT",
};

export const SortVideoCategory = {
  url: "admin/video_categories/sortable_video_categories",
  method: "PUT",
};

export const VideoListing = {
  url: "admin/video_categories/videos_listing_according_to_category",
  method: "GET",
};

export const VideoListingAdmissions = {
  url: "admission_portal/admin/video_categories/videos_listing_according_to_category",
  method: "GET",
};

export const ArchivedVideoListing = {
  url: "admin/video_categories/archived_video_catgeory_listing",
  method: "GET",
};

export const AddResource = {
  url: "admin/videos",
  method: "POST",
};

export const AddResourceAdmission = {
  url: "admission_portal/admin/videos",
  method: "POST",
};

export const getVideoById = {
  url: "admin/videos/",
  method: "GET",
};

export const updateVideoById = {
  url: "admin/videos/",
  method: "PATCH",
};

export const updateVideoByIdAdmissions = {
  url: "admission_portal/admin/videos/",
  method: "PATCH",
};

export const sortingVideo = {
  url: "admin/videos/sortable_videos",
  method: "PUT",
};

export const deleteVideoById = {
  url: "admin/videos/",
  method: "DELETE",
};

export const archiveUnarchiveVideo = {
  url: "admin/videos/",
  method: "PUT",
};

export const ArchiveVideosListing = {
  url: "admin/videos/archive_videos_listing",
  method: "GET",
};

export const ArchiveVideosListingAdmission = {
  url: "admission_portal/admin/videos/archive_videos_listing",
  method: "GET",
};

//add School service
export const AddSchoolService = {
  url: "/add",
  method: "POST",
};

//add Blog
export const AddBlog = {
  url: "/add",
  method: "POST",
};

//get list of deals
export const GetServiceList = {
  url: "admin/deals",
  method: "GET",
};
//get list of deals - Admissions
export const GetServiceListAdmissions = {
  url: "admission_portal/admin/deals",
  method: "GET",
};

//deals of particular id
export const GetServiceById = {
  url: "admin/deals/",
  method: "GET",
};

//Delete particular service
export const DeleteServiceById = {
  url: "admin/deals/",
  method: "DELETE",
};

//add deals
export const AddService = {
  url: "admin/deals",
  method: "POST",
};

//add deals - Admissions
export const AddServiceAdmission = {
  url: "admission_portal/admin/deals",
  method: "POST",
};

export const UpdateService = {
  url: "admin/deals/",
  method: "PUT",
};

export const UpdateServiceAdmissions = {
  url: "admission_portal/admin/deals/",
  method: "PUT",
};

export const GetPromoteList = {
  url: "admin/promotes",
  method: "GET",
};

export const GetPromoteById = {
  url: "admin/promotes/",
  method: "GET",
};

export const AddPromote = {
  url: "admin/promotes",
  method: "POST",
};

export const UpdatePromote = {
  url: "admin/promotes/",
  method: "PUT",
};

export const DeletePromoteById = {
  url: "admin/promotes/",
  method: "DELETE",
};

export const SortPromote = {
  url: "admin/promotes/sortable_promotes",
  method: "PUT",
};

//get archive deals
export const GetArchiveList = {
  url: "admin/deals/hidden_deals",
  method: "GET",
};

//get archive deals
export const moveToArchive = {
  url: "admin/deals/",
  method: "POST",
};

export const SortDeal = {
  url: "admin/deals/sortable_deals",
  method: "PUT",
};

//get list of categories
export const GetCategoryList = {
  url: "admin/deal_categories",
  method: "GET",
};
//get list of categories - Admissions
export const GetCategoryListAdmissions = {
  url: "admission_portal/admin/deal_categories",
  method: "GET",
};
//delete category
export const DeleteCategory = {
  url: "admin/deal_categories/",
  method: "DELETE",
};

//Checklists
export const GetChecklistCategories = {
  url: "admin/checklists/checklist_categories",
  method: "GET",
};

export const GetChecklistCategoriesAdmissions = {
  url: "admission_portal/admin/checklists/checklist_categories",
  method: "GET",
};

export const GetCategoryTasks = {
  url: "admin/checklists/get_tasks_by_category",
  method: "GET",
};

export const GetArchivedListing = {
  url: "admin/checklists/archived_task_listing",
  method: "GET",
};

export const GetArchivedListingAdmissions = {
  url: "admission_portal/admin/checklists/archived_task_listing",
  method: "GET",
};

export const AddChecklistCategory = {
  url: "admin/checklists",
  method: "POST",
};

export const AddChecklistCategoryAdmission = {
  url: "admission_portal/admin/checklists",
  method: "POST",
};

export const EditChecklistCategory = {
  url: "admin/checklists/",
  method: "PATCH",
};

export const GetCategoryForChecklist = {
  url: "admin/checklists/",
  method: "GET",
};

export const DeleteChecklistCategory = {
  url: "admin/checklists/",
  method: "DELETE",
};

export const AddTask = {
  url: "admin/checklists/add_task",
  method: "POST",
};

export const AddTaskAdmissions = {
  url: "admission_portal/admin/checklists/add_task",
  method: "POST",
};

export const EditTask = {
  url: "admin/checklists/update_task",
  method: "PATCH",
};

export const ArchiveTask = {
  url: "admin/checklists/archive_task",
  method: "PUT",
};

export const RestoreTask = {
  url: "admin/checklists/unarchive_task",
  method: "PUT",
};

export const DeleteTask = {
  url: "admin/checklists/remove_task",
  method: "DELETE",
};

export const SortTasks = {
  url: "admin/checklists/sortable_task",
  method: "PUT",
};

export const SortCategories = {
  url: "admin/checklists/sortable_category",
  method: "PUT",
};

export const ArchiveCategory = {
  url: "admin/checklists/archive_checklist_category",
  method: "PUT",
};

export const RestoreCategory = {
  url: "admin/checklists/unarchive_checklist_category",
  method: "PUT",
};

export const ResourceVideoPinAtTop = {
  url: "admin/videos/{video_id}/pin_at_top",
  method: "POST",
};

export const ResourceVideoUnPinFromTop = {
  url: "admin/videos/{video_id}/unpin_from_top",
  method: "POST",
};

export const StudentServicePinAtTop = {
  url: "admin/deals/{deal_id}/pin_at_top",
  method: "POST",
};

export const StudentServiceUnPinAtTop = {
  url: "admin/deals/{deal_id}/unpin_from_top",
  method: "POST",
};

export const HomeFeedPinAtTop = {
  url: "admin/user_posts/{post_id}/pin_at_top",
  method: "POST",
};

export const HomeFeedUnPinAtTop = {
  url: "admin/user_posts/{post_id}/unpin_from_top",
  method: "POST",
};
