import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { reducer } from "./reducer/reducer";

const logger = createLogger({});

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["webSocketStore"],
};
const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(
  persistedReducer,
  {},
  process.env.REACT_APP_ENV === "prod"
    ? applyMiddleware(thunk)
    : applyMiddleware(thunk, logger),
);
export const persistor = persistStore(store);
