import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import moment from "moment";
import { clone } from "underscore";

import { commonApiFN } from "../../common/api";
import {
  GetPermissionsForLoggedUser,
  GetPermissionsForSchoolAdmissions,
} from "../../common/module_wise_api_url/manageFeatures";
import KommunicateChat from "../../components/ChatBot/ChatBot";
import Loader from "../../components/Loader/Loader";
import { userTypes } from "../../Constants";
import { ChatServiceContextProvider } from "../../context/chatContext";
import routes from "../../routes";
import AddFormFooter from "../Common/addFormFooter";
import AddFormHeader from "../Common/addFormHeader";
import Header from "../Common/header";
import SideBar from "../Common/sidebar";
import MyProfile from "../MyProfile";
import { updateFeaturePermissions } from "../../redux/action";
import { GetFeedbackStatus } from "../../common/module_wise_api_url/common";
import FeedbackModal from "../../components/FeedbackModal/FeedbackModal";

const Layout = (props) => {
  const {
    user = {},
    isSuperAdmin = false,
    featurePermissionsExpiry = null,
    selectedPortal = false,
    isLogin = false,
  } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const [showFormLoader, setShowFormLoader] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [displaySaveButton, setDisplaySaveButton] = useState(true);
  const [extraButton, setExtraButton] = useState(false);
  const [alertClose, setAlertClose] = useState(false);
  const [stepType, setStepType] = useState(false);
  const [step, setStep] = useState(1);
  const [disableActions, setDisableActions] = useState(false);
  const [hideBackOption, setHideBackOption] = useState(false);
  const [backText, setBackText] = useState({
    backUrl: "",
    backUrlText: "",
  });
  const [displaySteps, setDisplaySteps] = useState(true);
  const [totalSteps, setTotalSteps] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFormFooter, setShowFormFooter] = useState(true);

  useEffect(() => {
    if (!props?.location?.pathname.includes("appointment")) {
      setDisplaySteps(true);
    }
  }, [props?.location]);

  const getUserPermissions = async () => {
    if (!isLogin || isSuperAdmin) return;
    if (user.user_type !== userTypes.GENERAL_ADVISOR) {
      let requestData = clone(
        selectedPortal === "admission"
          ? GetPermissionsForSchoolAdmissions
          : GetPermissionsForLoggedUser
      );
      const response = await commonApiFN(requestData);

      if (response && response.success) {
        dispatch(updateFeaturePermissions(response.data));
      }
    }
  };

  const fetchFeedbackStatus = async () => {
    if (!isLogin || isSuperAdmin || !user?.id) return;
    let request = clone(GetFeedbackStatus);
    request.url = request.url + user.id;
    const response = await commonApiFN(request);

    if (response && response.success && response.data) {
      const { boost_profile = {} } = response.data;
      const feedbackStatus = boost_profile?.rate_interstride;
      if (user?.sign_in_count && user?.sign_in_count > 3) {
        const feedbackDialogTimestamp = localStorage.getItem(
          "feedback_dialog_timestamp"
        );
        if (
          feedbackStatus === false &&
          (!feedbackDialogTimestamp ||
            moment().isAfter(moment(feedbackDialogTimestamp)))
        )
          setShowFeedbackModal(true);
        else setShowFeedbackModal(false);
      }
    }
  };

  useEffect(() => {
    if (!isLogin) {
      props.history.push("/");
    } else {
      fetchFeedbackStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  useEffect(() => {
    getUserPermissions();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortal]);

  const displaySaveButtonFn = (show) => {
    setDisplaySaveButton(show);
  };
  const displayStepsFn = (show) => {
    setDisplaySteps(show);
  };

  const setSteepingFn = (step) => {
    setStep(step);
  };

  const updateSaveButtonText = (text) => {
    setSaveButtonText(text);
  };
  const updateBackUrl = (backUrl, backUrlText) => {
    setBackText({
      backUrl,
      backUrlText,
    });
  };

  const drawerOpenFn = (val) => {
    setIsDrawerOpen(val);
  };

  const cancelButtonFn = () => {
    setCancelButton(!cancelButton);
  };

  const saveButtonFn = () => {
    setSaveButton(!saveButton);
  };

  const extraButtonFn = () => {
    setExtraButton(!extraButton);
  };

  const alertCloseFn = (val) => {
    setAlertClose(val);
  };

  const changeDisableActions = (value) => {
    setDisableActions(value);
  };

  useEffect(() => {
    setCancelButton(false);
    setSaveButton(false);
    setExtraButton(false);
  }, []);

  useEffect(() => {
    if (
      !isSuperAdmin &&
      (featurePermissionsExpiry === null ||
        moment().isAfter(moment(featurePermissionsExpiry)))
    ) {
      getUserPermissions();
    }
    if (user.user_type !== userTypes.GENERAL_ADVISOR) {
      setStep(1);
    }
    setDisableActions(false);
    setHideBackOption(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    setSteepingFn(step);
    setSaveButton(false);
    setExtraButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSteepingFn]);

  return (
    <section
      className={`main-content
       ${
         window.location.pathname === "/dashboard"
           ? alertClose
             ? "alert-open"
             : ""
           : ""
       }     
       ${isDrawerOpen ? "Sidebar-open" : ""}`}
      id="main-content"
    >
      {props.location.state && props.location.state.first_time ? (
        <MyProfile FirstTimeLogin={true} history={props.history} />
      ) : (
        <>
          <SideBar drawerOpenFn={drawerOpenFn} />
          <Header history={props.history} />
          <div
            className={`page-content-wrap ${
              showFormLoader ? "form-loader" : ""
            }`}
          >
            <ChatServiceContextProvider>
              <Suspense fallback={<Loader />}>
                <Switch>
                  {routes.map((route, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <>
                              <div className="wizzard-content">
                                {(route.addType || route.showHeader) && (
                                  <AddFormHeader
                                    hideBackOption={hideBackOption}
                                    backUrl={
                                      route.backUrl
                                        ? route.backUrl
                                        : backText.backUrl
                                    }
                                    backUrlText={
                                      route.backUrlText
                                        ? route.backUrlText
                                        : backText.backUrlText
                                    }
                                    displaySteps={displaySteps}
                                    step={step}
                                    totalStepCount={
                                      totalSteps > 0
                                        ? totalSteps
                                        : route.totalStepCount
                                    }
                                  />
                                )}

                                <route.component
                                  backUrl={route.backUrl || ""}
                                  stepType={route.stepType}
                                  totalStepCount={
                                    totalSteps > 0
                                      ? totalSteps
                                      : route.totalStepCount
                                  }
                                  cancelButton={cancelButton}
                                  saveButton={saveButton}
                                  extraButton={extraButton}
                                  step={step}
                                  saveButtonFn={saveButtonFn}
                                  setSteepingFn={setSteepingFn}
                                  extraButtonFn={extraButtonFn}
                                  alertCloseFn={alertCloseFn}
                                  showFormLoader={showFormLoader}
                                  setShowFormLoader={setShowFormLoader}
                                  setDisableFormActions={changeDisableActions}
                                  setHideBackOption={setHideBackOption}
                                  stepCount={route.step}
                                  setStepType={setStepType}
                                  updateSaveButtonText={updateSaveButtonText}
                                  updateBackUrl={updateBackUrl}
                                  displaySaveButtonFn={displaySaveButtonFn}
                                  displayStepsFn={displayStepsFn}
                                  cancelButtonFn={cancelButtonFn}
                                  setTotalSteps={setTotalSteps}
                                  setShowFormFooter={setShowFormFooter}
                                  {...props}
                                />

                                {route.addType && showFormLoader ? (
                                  <Loader />
                                ) : (
                                  <>
                                    {route.addType &&
                                      showFormFooter &&
                                      route.path !==
                                        "/admissions/chat/add-message" &&
                                      route.path !== "/chat/add-message" &&
                                      route.path !==
                                        "/post/manage-categories" &&
                                      route.path !== "/manage-rss/event" &&
                                      route.path !== "/manage-rss/job" && (
                                        <div
                                          className={`footer-wrapper ${
                                            !isDrawerOpen ? "drawerOpen" : ""
                                          }`}
                                        >
                                          <div className="wizard-button">
                                            <AddFormFooter
                                              dynamicFormSteps={
                                                route.dynamicFormSteps
                                              }
                                              stepTypeProps={route.stepType}
                                              stepType={stepType}
                                              setStepType={setStepType}
                                              totalStepCount={
                                                totalSteps > 0
                                                  ? totalSteps
                                                  : route.totalStepCount
                                              }
                                              setSteepingFn={setSteepingFn}
                                              cancelButtonFn={cancelButtonFn}
                                              saveButton={saveButton}
                                              saveButtonFn={saveButtonFn}
                                              extraButton={extraButton}
                                              extraButtonFn={extraButtonFn}
                                              step={step}
                                              showSkip={route.showSkip}
                                              disableActions={disableActions}
                                              saveButtonText={
                                                route.saveButtonText
                                                  ? route.saveButtonText
                                                  : saveButtonText
                                              }
                                              extraButtonText={
                                                route.extraButtonText
                                                  ? route.extraButtonText
                                                  : false
                                              }
                                              hideBackOption={hideBackOption}
                                              hideCancelButton={
                                                route.hideCancelButton
                                              }
                                              updateSaveButtonText={
                                                updateSaveButtonText
                                              }
                                              displaySaveButtonFn={
                                                displaySaveButtonFn
                                              }
                                              displaySteps={displaySteps}
                                              displaySaveButton={
                                                displaySaveButton
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        />
                      )
                    );
                  })}{" "}
                  <Redirect from="/" to="/dashboard" />{" "}
                </Switch>{" "}
              </Suspense>
            </ChatServiceContextProvider>
          </div>
          <KommunicateChat />
          {showFeedbackModal && (
            <FeedbackModal handleModal={setShowFeedbackModal} />
          )}
        </>
      )}
    </section>
  );
};

export default Layout;
