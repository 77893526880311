import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clone } from "underscore";
import { useSelector } from "react-redux";

import { commonApiFN } from "../../common/api";
import { toastify } from "../../helper/helper";
import { UnsubscribeNotifications } from "../../common/module_wise_api_url/notifications";
import ButtonCustom from "../../components/Button/Button";
import { INTERSTRIDE_DEFAULT_LOGO } from "../../Constants";

const NotificationUnsubscribe = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const [unsubscribed, setUnsubscribed] = useState(false);
  const [failureCount, setFailureCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const FAIL_ATTEMPT_LIMIT = 3;

  const { isLogin = false } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (!token) redirectToHomeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToHomeScreen = () => {
    props.history.push("/");
  };

  const handleUnsubscribeClick = async () => {
    setIsSubmitting(true);

    let request = clone(UnsubscribeNotifications);
    request.data = {
      token,
    };
    const response = await commonApiFN(request);

    if (response.success) {
      if (response?.message) {
        toastify("success", "You've successfully unsubscribed.");
        setUnsubscribed(true);
      }
    } else {
      setFailureCount(failureCount + 1);
      if (response?.message) {
        toastify("error", response.message);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div className="container text-center notification-unsubscribe">
      <div>
        <div className="BrandLogo text-center">
          <a href="/">
            <img
              src={INTERSTRIDE_DEFAULT_LOGO}
              style={{ height: 36 }}
              alt="logo"
            />
          </a>
        </div>
        <h1 className="title text-primary">
          {unsubscribed ? "Success!" : "Unsubscribe email notifications"}
        </h1>
        <h3 className="subtitle text-primary m-4">
          {unsubscribed
            ? "You've successfully unsubscribed from all Interstride email notifications."
            : "Click below to unsubscribe from all Interstride email notifications."}
        </h3>

        {unsubscribed ? (
          <ButtonCustom onClick={redirectToHomeScreen}>
            {isLogin ? "Dashboard" : "Login"}
          </ButtonCustom>
        ) : (
          <ButtonCustom
            onClick={handleUnsubscribeClick}
            isDisabled={isSubmitting || failureCount >= FAIL_ATTEMPT_LIMIT}
            isSubmitting={isSubmitting}
          >
            Unsubscribe
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

export default NotificationUnsubscribe;
