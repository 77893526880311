//Manage User

//  get Department list
export const GetDepartment = {
  url: "admin/dashboard/department_list",
  method: "GET",
};

// POST
export const GetDropdownData = {
  url: "admin/dashboard/notification_dropdown_value_for_all_modules",
  method: "GET",
};

// Get School options
export const GetSchoolList = {
  url: "admin/dashboard/school_list",
  method: "GET",
};

// Get School options admissions
export const GetSchoolListAdmissions = {
  url: "admin/dashboard/school_list?admission_portal=true",
  method: "GET",
};

// Get all school options for super admin
export const GetAllSchoolList = {
  url: "admin/dashboard/school_list?all=true",
  method: "GET",
};

export const GetUserFilters = {
  url: "admin/dashboard/notification_dropdown_value_for_all_modules",
  method: "GET",
};

export const GetCountries = {
  url: "countries",
  method: "GET",
};

export const GetNotificationFilters = {
  url: "admin/dashboard/notification_dropdown",
  method: "GET",
};

export const GetTotalNotificationAudienceCount = {
  url: "admin/dashboard/total_recipients_send_notification",
  method: "GET",
};

export const GetNotificationFiltersAdmissions = {
  url: "admission_portal/admin/dashboard/notification_dropdown",
  method: "GET",
};

export const GetTotalNotificationAudienceCountAdmissions = {
  url: "admission_portal/admin/dashboard/total_recipients_send_notification",
  method: "GET",
};

export const DeleteAccount = {
  url: "admin/deactivate",
  method: "POST",
};

export const GetFeedbackStatus = {
  url: "boost_profiles/",
  method: "GET",
};
