import { combineReducers } from "redux";

import chatReducer from "../chat/reducer";
import loginReducer from "./loginReducer";
import notificationReducer from "./notificationReducer";
import webSocketReducer from "./webSocketReducer";

export const reducer = combineReducers({
  loginReducer,
  chatStore: chatReducer,
  webSocketStore: webSocketReducer,
  notificationStore: notificationReducer,
});
