import { NOTIFICATION_FILTERS } from "../../Constants";

export const FetchAllNotificationCount = {
  url: "admin/notifications/all_notifications_count",
  method: "GET",
};

export const GetAllNotifications = {
  url: "admin/notifications",
  method: "GET",
  params: {
    filter_type: NOTIFICATION_FILTERS.map((filter) => filter.value),
  },
};

export const ReadAllNotifications = {
  url: "admin/notifications/read_all_notification",
  method: "GET",
};

export const ReadNotification = {
  url: "admin/notifications/",
  method: "GET",
};

export const RemoveAllPastNotifications = {
  url: "admin/notifications/remove_all_past_notifications",
  method: "GET",
};

export const RemoveNotification = {
  url: "admin/notifications/",
  method: "GET",
};

export const GetNotificationSettings = {
  url: "admin/notification_settings/",
  method: "GET",
};

export const UpdateNotificationSettings = {
  url: "admin/notification_settings",
  method: "POST",
};

export const UpdateUnsubscribeNotification = {
  url: "admin/notification_settings/update_notifications",
  method: "PUT",
};

export const ReadChatNotification = {
  url: "admin/notifications/read_chat_notifications",
  method: "POST",
};

export const UnsubscribeNotifications = {
  url: "admin/notification_settings/unsubsrcibe_email_without_signup",
  method: "PUT",
};
