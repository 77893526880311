import React from "react";
import { useDebouncedCallback } from "use-debounce";

import { getValidSearchText } from "../../helper/helper";
import Textbox from "../Textbox/Textbox";
import SearchTextboxStyles from "./SearchTextboxStyles";

import SearchIcon from "../../assets/svg/search.svg";

export default function SearchTextbox({
  disable = false,
  search = false,
  searchTab = () => {},
  searchData = {},
  setSearchData = () => {},
  onSearch = () => {},
  debounceInterval = 500,
  searchPlaceholder = "Type to search something",
}) {
  const debouncedSearchingFn = useDebouncedCallback((search) => {
    onSearch(search);
  }, debounceInterval);

  return (
    <SearchTextboxStyles>
      <div className={`search-textbox-container ${search ? "selected" : ""}`}>
        <a
          href
          className="search-icon"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!disable) searchTab(!search);
          }}
        >
          <img src={SearchIcon} alt={"Search"} />
        </a>
        {search && (
          <div className="textbox-container">
            <Textbox
              autoFocus
              placeholder={searchPlaceholder}
              value={searchData?.text}
              onChange={(e) => {
                const text = getValidSearchText(e.target.value);
                setSearchData({
                  ...searchData,
                  text,
                });
                debouncedSearchingFn(text);
              }}
            />
          </div>
        )}
      </div>
    </SearchTextboxStyles>
  );
}
