import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Button from "../Button/Button";
import DialogStyles, { PTag } from "./DialogStyles";

import closeBtn from "../../../src/assets/svg/close.svg";

export default function DialogCustom({
  open = false,
  header = "",
  subheader = null,
  ptag = null,
  onClose = () => {},
  showCancelButton = true,
  cancelButtonLabel = "Cancel",
  customStyle = false,
  showActionButton = true,
  showCloseBtn = false,
  onActionClick = () => {},
  actionButtonLabel = "Ok",
  className,
  children,
  isActionLoading,
  disableBackdropClick = false,
  ...props
}) {
  return (
    <Dialog
      onClose={onClose}
      disableBackdropClick = {disableBackdropClick}
      disableEscapeKeyDown
      className={className ? className : ""}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogStyles customStyle={customStyle}>
        <DialogTitle
          className={`${!customStyle && "mb-15"}`}
          id="confirmation-dialog-title"
        >
          {header}
        </DialogTitle>
        {(subheader || children || ptag) && (
          <DialogContent>
            {subheader && <div className="text-subheader">{subheader}</div>}
            {children && <div>{children}</div>}
            {ptag && <PTag>{ptag}</PTag>}
          </DialogContent>
        )}

        {(showActionButton || showCancelButton || showCloseBtn) && (
          <DialogActions className="mt-20">
            {showCancelButton && (
              <Button onClick={onClose} type="secondary">
                {cancelButtonLabel}
              </Button>
            )}
            {showActionButton && (
              <Button
                onClick={onActionClick}
                type="primary"
                isSubmitting={isActionLoading}
              >
                {actionButtonLabel}
              </Button>
            )}
            {showCloseBtn && (
              <img
                className="cancel-icon"
                src={closeBtn}
                width="15px"
                height="15px"
                onClick={onClose}
                alt="cancel"
              />
            )}
          </DialogActions>
        )}
      </DialogStyles>
    </Dialog>
  );
}
