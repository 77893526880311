import React from "react";
import { CircleSpinner } from "react-spinners-kit";

import { IconButton, Tooltip } from "@mui/material";

export default function IconButtonCustom({
  onClick = () => {},
  children,
  type = "primary",
  isDisabled = false,
  title = null,
  tooltip = "",
  isSubmitting = false,
}) {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={onClick}
        disabled={isDisabled ? isDisabled : isSubmitting}
        title={title}
      >
        {children}
        {isSubmitting && (
          <span className="button-spin">
            <CircleSpinner
              color={
                type === "primary" || type === "rounded" || type === "dark"
                  ? "#000C3D"
                  : "#fff"
              }
              size={20}
            />
          </span>
        )}
      </IconButton>
    </Tooltip>
  );
}
