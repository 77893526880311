import React from "react";

import CheckboxStyles from "./CheckboxStyles";

import Selected from "../../assets/svg/Selected-checkbox.svg";
import UnSelected from "../../assets/svg/uncheck-input.svg";

export default function CheckboxCustom({
  value,
  isDisabled = false,
  isChecked = false,
  onClick = (e) => {},
}) {
  return (
    <CheckboxStyles>
      <div className="custom-checkbox">
        {isChecked ? (
          <input
            type="checkbox"
            onClick={onClick}
            defaultChecked
            value={value}
          />
        ) : (
          <input type="checkbox" onClick={onClick} value={value} disabled={isDisabled}/>
        )}

        <span className="checkmark">
          {isChecked ? (
            <img src={Selected} alt="selected" disabled={isDisabled}/>
          ) : (
            <img src={UnSelected} alt="unselected" disabled={isDisabled}/>
          )}
        </span>
      </div>
    </CheckboxStyles>
  );
}
