import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip } from "@mui/material";
import moment from "moment";
import { clone } from "underscore";

import { commonApiFN } from "../../../common/api";
import { ReadNotification } from "../../../common/module_wise_api_url/notifications";

import NotificationCommon from "../../../assets/svg/notification/common.svg";
import NotificationMessage from "../../../assets/svg/notification/Messages.svg";
import NotificationPost from "../../../assets/svg/notification/Post.svg";
import NotificationResources from "../../../assets/svg/notification/Resources.svg";
import NotificationServices from "../../../assets/svg/notification/StudentServices.svg";
import NotificationTopics from "../../../assets/svg/notification/Topics.svg";
import NotificationWebinar from "../../../assets/svg/notification/Webinars.svg";
import Deleteimage from "../../../assets/svg/Delete.svg";
import GoIcon from "../../../assets/svg/Go.svg";
import ExpandIcon from "../../../assets/svg/expand.svg";

const getNotificationInfo = ({ portal_type = "career", type }) => {
  switch (type) {
    case "network_messages":
    case "group_messages":
    case "missed_message":
      return {
        buttonLabel: "View Network",
        redirectURL:
          portal_type === "career"
            ? "/chat/messages"
            : "/admissions/chat/messages",
        icon: NotificationMessage,
        tagLabel: "Network",
      };
    case "new_topics":
    case "created_topics":
    case "followed_topics":
    case "follow_requests":
      return {
        buttonLabel: "View Community",
        redirectURL:
          portal_type === "career"
            ? "/communication/topics"
            : "/admissions/chat/topics",
        icon: NotificationTopics,
        tagLabel: "Community",
      };
    case "user_post":
      return {
        buttonLabel: "View Post",
        redirectURL:
          portal_type === "career"
            ? "/post/home-feed"
            : "/admissions/post/home-feed",
        icon: NotificationPost,
        tagLabel: "Post",
      };
    case "webinar":
      return {
        buttonLabel: "View Webinar",
        redirectURL:
          portal_type === "career"
            ? "/post/events"
            : "/admissions/post/webinars",
        icon: NotificationWebinar,
        tagLabel: "Webinar",
      };
    case "deal":
      return {
        buttonLabel: `View Service`,
        redirectURL:
          portal_type === "career"
            ? "/post/student-services"
            : "/admissions/post/student-services",
        icon: NotificationServices,
        tagLabel: "Student services",
      };
    case "video":
      return {
        buttonLabel: `View Resource`,
        redirectURL:
          portal_type === "career"
            ? "/post/resources"
            : "/admissions/post/resources",
        icon: NotificationResources,
        tagLabel: "Resource",
      };
    case "user_invitation":
      return {
        buttonLabel: `View`,
        redirectURL:
          portal_type === "career"
            ? "/manage-user/admin"
            : "/admissions/manage-user/admin",
        icon: NotificationCommon,
        tagLabel: "Invitation",
      };
    default:
      return {
        buttonLabel: `View`,
        redirectURL:
          portal_type === "admission" ? "/admissions/dashboard" : "/dashboard",
        icon: NotificationCommon,
      };
  }
};

const NotificationItem = ({
  notification = {},
  onDeleteClick,
  onViewClick = () => {},
  variant = "large",
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedPortal } = useSelector((store) => store.loginReducer);
  const { buttonLabel, redirectURL, icon, tagLabel } =
    getNotificationInfo(notification);

  const timeLabel = moment(notification.created_at).fromNow(true);

  const readNotificationAndRedirect = async () => {
    const request = clone(ReadNotification);
    request.url = request.url + notification.id + "/read";

    const response = await commonApiFN(request);

    if (response.success) {
      // Set portal type if different from notification
      if (selectedPortal !== notification.portal_type) {
        dispatch({
          type: "UPDATE_SELECTED_PORTAL",
          res: notification.portal_type,
        });
      }
      // Redirect the user
      setTimeout(() => {
        history.push(redirectURL + `?type_id=${notification.type_id || null}`);
      }, 100);
      onViewClick(response?.data?.notification?.id);
    }
  };

  if (variant === "small") {
    return (
      <div
        className={"notification-item cursor-pointer"}
        onClick={() => {
          if (variant === "small") readNotificationAndRedirect();
        }}
      >
        <div className="notification-container1">
          <div className="small-notification-container">
            <div className="notification-container2">
              <div className="img-wrap">
                <img src={icon} alt="notification-icon" />
              </div>

              <div className={"small-text-content"}>
                <h3>{notification?.message ?? notification?.title ?? ""}</h3>
                <div className="d-flex align-items-center">
                  <h5 className="tag-label">{tagLabel}</h5>
                  <h6 className="time-label">{timeLabel} ago</h6>
                </div>
              </div>
            </div>
            <div className="small-actions-div">
              <a
                href
                onClick={(e) => {
                  e.preventDefault();
                  readNotificationAndRedirect();
                }}
              >
                <img src={GoIcon} alt="Go to Notification" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={"notification-item"}
      onClick={() => {
        if (variant === "small") readNotificationAndRedirect();
      }}
    >
      <div className="notification-container1">
        <div className="notification-container2">
          <div className="img-wrap">
            <img src={icon} alt="notification-icon" />
          </div>

          <a
            href
            onClick={(e) => {
              e.preventDefault();
              readNotificationAndRedirect();
            }}
          >
            <div className="text-content">
              <h2>{tagLabel}</h2>
              <h3>
                {notification.message
                  ? notification.message
                  : notification.title
                  ? notification.title
                  : ""}
              </h3>
              <img src={ExpandIcon} alt={buttonLabel} />
              <h6>{timeLabel} ago</h6>
            </div>
          </a>

          <div className="actions-div">
            <Tooltip title="Archive">
              <a
                href
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteClick(notification.id);
                }}
              >
                <img src={Deleteimage} alt="Archive" />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NotificationItem);
