//example
export function setUserData(res) {
  return { type: "SET_USER_DATA", res };
}

export function updateUserData(res) {
  return { type: "UPDATE_USER_DATA", res };
}

export function updateUser(res) {
  return { type: "UPDATE_USER", res };
}

export function resetUserData() {
  return { type: "RESET_USER_DATA" };
}

export function updateSelectedSchool(res) {
  return { type: "UPDATE_SELECTED_SCHOOL", res };
}

export function refreshSchoolList() {
  return { type: "REFRESH_SCHOOL_LIST" };
}

export function updateSelectedPortal(res) {
  return { type: "UPDATE_SELECTED_PORTAL", res };
}

export function updateFeaturePermissions(res) {
  return { type: "UPDATE_FEATURE_PERMISSIONS", res };
}

export function updateSSOStatus(res) {
  return { type: "UPDATE_SSO_STATUS", res };
}

export function setSavedRoute(res) {
  return { type: "SET_SAVED_ROUTE", res };
}

export function wipeOutSavedRoute(res) {
  return { type: "WIPEOUT_SAVED_ROUTE", res };
}
