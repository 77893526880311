import React, { useState } from "react";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import { noop } from "underscore";

import Dialog from "../../components/Dialog/Dialog";
import {
  dataURLtoFile,
  getFilenameFromLink,
  isURL,
  cropperToFile,
  fileSizeExceeds,
  toastify,
} from "../../helper/helper";
import {
  getAcceptedTypes,
  getFileNameValidation,
  MULTI_MEDIA_TYPES,
  validateMultimedia,
} from "../../utils/common";
import FileUploadStyles from "./FileUploadStyles";
import PDFPreview from "../PDFPreview/PDFPreview";
import "cropperjs/dist/cropper.css";

import docIcon from "../../assets/svg/icon-doc-white.svg";
import excelIcon from "../../assets/svg/icon-excel-white.svg";
import htmlIcon from "../../assets/svg/icon-html-white.svg";
import pptIcon from "../../assets/svg/icon-ppt-white.svg";
import xmlIcon from "../../assets/svg/icon-xml-white.svg";
import Uploading from "../../assets/svg/Upload.svg";

export default function FileUpload({
  handleUploadFile = (e) => {},
  text,
  vallidFiles,
  icon = null,
  imagePreview = false,
  type,
  file = null,
  error = false,
  errorText,
  cropperType = "square",
  dropzoneType,
  disabled = false,
  applySizeLimitInMB = -1, // -1 open limit
  compositeApplySizeLimitInMB = -1,
  onReject = noop,
  cropBoxResizable = true,
  aspectRatio = undefined,
  cropBoxMovable = true,
  props = null,
  customThumbnail = false,
  thumbnail = null,
  thumbnailIcon = null,
  setThumbnail = () => {},
}) {
  const [image, setImage] = useState();
  const [url, setURL] = useState();
  const [cropper, setCropper] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [cropperFileSize, setCropperFileSize] = useState(applySizeLimitInMB);

  const imageType = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];
  const videoType = ["video/mp4", "video/quicktime"];

  const getOtherTypeIcon = () => {
    //excel
    if (
      [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ].includes(type)
    )
      return excelIcon;
    //ppt
    else if (
      [
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ].includes(type)
    )
      return pptIcon;
    //doc
    else if (
      [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(type)
    )
      return docIcon;
    //xml
    else if (["application/xml", "text/xml"].includes(type)) return xmlIcon;
    //html
    else if (["text/html"].includes(type)) return htmlIcon;
    //other
    else return Uploading;
  };

  const handleCallback = (file) => {
    try {
      if (applySizeLimitInMB !== -1) {
        const { size } = file[0] || {};
        if (size) {
          if (fileSizeExceeds(size, applySizeLimitInMB)) {
            onReject(`File size should be < ${applySizeLimitInMB}MB`);
            return;
          }
        }
      } else if (compositeApplySizeLimitInMB !== -1) {
        const compositeSize = imageType.includes(file[0]?.type)
          ? compositeApplySizeLimitInMB?.imageFilesLimit
          : compositeApplySizeLimitInMB?.otherFilesLimit;
        setCropperFileSize(compositeSize);
        const { size } = file[0] || {};
        if (size) {
          if (fileSizeExceeds(size, compositeSize)) {
            onReject(`File size should be < ${compositeSize}MB`);
            return;
          }
        }
      }
      file.forEach((item) => {
        if (imageType.includes(item.type)) {
          setURL(item.name);
          setImage(URL.createObjectURL(item));
          setShowDialog(true);
        } else handleUploadFile(file, props);
      });
    } catch (error) {
      ///ignore
      console.log(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDrag: true,
    disabled,
    onDrop: handleCallback,
  });

  const initialFileSize = parseInt(file?.size) / 1024 / 1024;

  const CustomThumbnail = () => {
    const acceptedThumbnailMedia = [MULTI_MEDIA_TYPES.THUMBNAIL];
    const acceptedThumbnailTypes = getAcceptedTypes(acceptedThumbnailMedia);

    const onThumbnailDrop = (droppedFiles) => {
      let showError = true;
      try {
        droppedFiles.forEach((file) => {
          if (!file || !file.type || !file.size) return;

          const { valid, accepted, errorMsg } = validateMultimedia(
            file,
            acceptedThumbnailMedia
          );

          if (!valid) {
            toastify(
              "error",
              `Invalid thumbnail file ${file.name}. Only ${
                file.name
              }. Only ${getFileNameValidation(
                acceptedThumbnailTypes
              )} are allowed.`
            );
          } else {
            if (accepted) {
              setThumbnail(file);
            } else {
              if (showError) {
                toastify("error", errorMsg);
                showError = false;
              }
            }
          }
        });
      } catch (error) {
        ///ignore
        console.log(error);
      }
    };

    const {
      getRootProps: getThumbnailRootProps,
      getInputProps: getInputThumbnailProps,
      open: openThumbnail,
    } = useDropzone({
      noClick: true,
      noKeyboard: true,
      multiple: false,
      noDrag: true,
      onDrop: onThumbnailDrop,
    });

    return (
      <div
        className="thumbnail-dropzone"
        {...getThumbnailRootProps({})}
        onClick={openThumbnail}
      >
        <input {...getInputThumbnailProps()} accept={acceptedThumbnailTypes} />

        {thumbnail || thumbnailIcon ? (
          <a href>Change Thumbnail</a>
        ) : (
          <a href>Custom Thumbnail</a>
        )}
      </div>
    );
  };

  return (
    <FileUploadStyles>
      <div className={`fileupload-custom ${error ? "error" : ""}`}>
        <div
          className="dropzone-custom"
          {...getRootProps({
            className: `dropzone  ${disabled ? "disabled" : "cursor-pointer"} ${
              dropzoneType === "rounded" ? "rounded-dropzone" : ""
            }`,
          })}
          onClick={open}
        >
          <input {...getInputProps()} accept={vallidFiles} />

          {imageType.includes(type) || imagePreview ? (
            <div
              className={`upload-img uploaded ${
                icon ? "preview" : "placeholder"
              }`}
            >
              <img src={icon || Uploading} alt={"uploading"} />
            </div>
          ) : videoType.includes(type) ? (
            <video
              src={icon}
              width="320"
              height="240"
              controls
              poster={customThumbnail && thumbnailIcon ? thumbnailIcon : ""}
            />
          ) : ["application/pdf"].includes(type) ? (
            <>
              {customThumbnail && thumbnailIcon ? (
                <div className={`upload-img uploaded preview`}>
                  <img src={thumbnailIcon} alt={"thumbnail"} />
                </div>
              ) : (
                <div className="file-upload-pdf-preview">
                  <PDFPreview
                    file={file instanceof File ? file : icon}
                    height={242}
                  />
                </div>
              )}
            </>
          ) : (
            <img
              src={getOtherTypeIcon()}
              height="242"
              className="other-type-preview"
              alt={"other type"}
            />
          )}
        </div>
        {type && !imageType.includes(type) && !videoType.includes(type) && (
          <div className="file-name-preview">
            {file?.name
              ? file.name
              : isURL(icon)
              ? getFilenameFromLink(icon)
              : icon || ""}
          </div>
        )}

        {/* Custom thumbnail support for videos and pdf */}
        {customThumbnail &&
        file &&
        (videoType.includes(type) || ["application/pdf"].includes(type)) ? (
          <CustomThumbnail />
        ) : (
          <div className="cursor-pointer">{text}</div>
        )}

        {error && errorText && <div className="error-text"> {errorText}</div>}
        {showDialog && (
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            onActionClick={() => {
              if (
                typeof cropper !== "undefined" &&
                cropper.getCroppedCanvas()
              ) {
                const re = /\.(png)$/i;
                if (re.test(url)) {
                  // if the image is an PNG
                  const base64Url = cropper.getCroppedCanvas().toDataURL();
                  const getObjectFile = dataURLtoFile(base64Url, url);
                  handleUploadFile([getObjectFile], props);
                } else {
                  cropperToFile(
                    cropper,
                    url,
                    handleUploadFile,
                    cropperFileSize,
                    props,
                    initialFileSize
                  );
                }
              }
              setShowDialog(false);
            }}
            actionButtonLabel="Crop Image"
            className="file-upload-content"
          >
            <div className={`d-flex justify-content-center w-500`}>
              <div className={`${cropperType}-croppper`}>
                <Cropper
                  zoomTo={0}
                  src={image}
                  viewMode={1}
                  background={false}
                  responsive={true}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  cropBoxResizable={cropBoxResizable}
                  dragCrop={false}
                  dragMode={"move"}
                  guides={false}
                  toggleDragModeOnDblclick={false}
                  cropBoxMovable={cropBoxMovable}
                  aspectRatio={aspectRatio}
                />
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </FileUploadStyles>
  );
}
