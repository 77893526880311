import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .search-textbox-container {
    margin-right: 10px;
    border-radius: 25px;
    box-shadow: 0 1px 14px 3px rgba(209,221,229,0.4);
    search-icon {
      min-width: 50px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--card-box-shadow);
      background-color: var(--white-color);
      border-radius: 100px;
      transition: all 0.3s;
    }
    .textbox-container {
      margin: 0;
      padding: 3px 30px 0 4px;
      width: 100%;
      max-width: 248px;
      > div {
        width: 100%;
        > div {
          width: 100%;
          > div {
            width: 100%;
          }
        }
      }
      .MuiInputBase-formControl {
        .MuiInputBase-input {
          padding: 0;
          height: 28px !important;
          min-height: auto;
          border-radius: 0 !important;
          border: 0;
          background: transparent;
          text-align: left;
          width: 100%;
          border-color: #607790;
          @media (max-width: 767px) {
            min-width: 180px;
            width: 100%;
          }
        }
      }
    }
  }

  .selected {
    display: flex;
    min-width: 298px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px #d1dde5;
    border-radius: 100px;
    transition: all 0.3s;
    .search-icon {
      box-shadow: none !important;
    }
  }
`;
