import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { clone, isEmpty } from "underscore";

import Loader from "../../../components/Loader/Loader";
import BasicTable from "../../../components/Table/Table";
import { commonApiFN } from "../../../common/api";
import {
  GetSchoolRssFeedsCareer,
  GetSchoolRssFeedsAdmissions,
  DeleteRssFeedCareers,
  DeleteRssFeedAdmissions,
} from "../../../common/module_wise_api_url/update";
import SearchTextbox from "../../../components/SearchTextbox/SearchTextbox";
import { toastify } from "../../../helper/helper";
import CommonFilterFn from "../../Common/commonFilter";

import CloseIcon from "../../../assets/svg/close.svg";

export const AddRSSOption = (rssType) => (
  <>
    <CommonFilterFn
      redirectUrl={`/post/add-${rssType}-rss`}
      buttonText={"Add RSS"}
    />
    <a
      target="_blank"
      rel="noreferrer"
      className="webinars__add-rss-tooltip"
      href="https://support.joinhandshake.com/hc/en-us/articles/222083748-School-Settings-External-Feeds"
    >
      <Tooltip title="Instructions" placement="top-start" tooltipArrow={true}>
        <InfoIcon />
      </Tooltip>
    </a>
  </>
);

const MultipleRssLinks = () => {
  const { selectedSchool = null } = useSelector((state) => state.loginReducer);
  const school_id = selectedSchool?.id || null;
  const [filter, filterTab] = useState(false);
  const [search, searchTab] = useState(false);
  const [rssLinks, setRssLinks] = useState([]);
  const [totalRecords, setTotalrecords] = useState("");
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    total_count: 0,
    total_page: 0,
  });
  const [searchData, setSearchData] = useState({ text: "", appliedText: "" });
  const [showLoader, setShowLoader] = useState(false);
  const type = window.location.pathname.split("manage-rss/")[1];
  let headers = [
    {
      name: "RSS Feed",
      sortIcon: false,
      sortType: false,
    },
    {
      name: "",
      sortType: false,
    },
  ];
  let data_keys = [{ name: "title" }, { name: "edit_delete" }];
  if (type === "job") {
    headers.splice(1, 0, { name: "position" });
    data_keys.splice(1, 0, { name: "options" });
  }

  useEffect(() => {
    setShowLoader(true);
    getMultipleRssLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPortal = useSelector(
    (state) => state.loginReducer.selectedPortal
  );

  const getMultipleRssLinks = async ({
    page,
    limit,
    resetCount = false,
    search = null,
    decreaseCount = false,
  } = {}) => {
    let params = {};
    const request = clone(
      selectedPortal === "admission"
        ? GetSchoolRssFeedsAdmissions
        : GetSchoolRssFeedsCareer
    );

    //Apply Searching
    request.url =
      request.url +
      `?search=${search !== null ? search : searchData.appliedText}`;
    //Apply School_id
    if (school_id) {
      params.school_id = school_id;
    }

    if (type && type !== undefined) {
      params.type = type;
    }

    //Apply Pagination
    params.pagination = {};
    params.pagination.page = page ? page : paginationData.page;
    params.pagination.per_page =
      (limit && limit === "All") || (!limit && paginationData.limit === "All")
        ? totalRecords
        : limit
        ? limit
        : paginationData.limit;
    request.params = params;

    const response = await commonApiFN(request);

    if (response.success) {
      if (params.pagination.page === 1 || resetCount) {
        setRssLinks(response.data.feeds);
      } else {
        setRssLinks((prevData) => [...prevData, ...response.data.feeds]);
      }
      if (totalRecords === "" || resetCount)
        setTotalrecords(response.data.total_feeds);
      else if (decreaseCount) {
        setTotalrecords((totalRecords) => totalRecords - 1);
      }

      setPaginationData((paginationData) => ({
        ...paginationData,
        total_count: response.data.total_feeds,
        total_page: response.data.total_pages,
      }));
    } else {
      setRssLinks([]);
      if (response?.data?.message) toastify("error", response.data.message);
    }
    setShowLoader(false);
  };

  const onSearch = (search) => {
    setPaginationData({
      ...paginationData,
      page: 1,
    });

    setSearchData({
      ...searchData,
      appliedText: search.trim(),
    });

    getMultipleRssLinks({ search: search.trim(), page: 1 });
  };

  const confirmDelete = async (id) => {
    const request = clone(
      selectedPortal === "admission"
        ? DeleteRssFeedAdmissions
        : DeleteRssFeedCareers
    );

    if (school_id) {
      request.params = { school_id };
    }

    request.url = request.url + id;
    request.notify = true;
    const response = await commonApiFN(request);

    if (response?.success) {
      setPaginationData({
        ...paginationData,
        page: 1,
        limit: paginationData.limit,
      });
      getMultipleRssLinks({
        page: 1,
        limit: paginationData.limit,
        decreaseCount: true,
      });
    }
  };

  const fetchMoreData = () => {
    setPaginationData((paginationData) => ({
      ...paginationData,
      page: paginationData.page + 1,
    }));
    getMultipleRssLinks({ page: paginationData.page + 1 });
  };

  const disableRecordActions = rssLinks.length === 0;

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <div
            className={`filter-wrap ${disableRecordActions ? "disabled" : ""}`}
          >
            <div className="filter-left">
              <SearchTextbox
                disable={disableRecordActions}
                search={search}
                searchTab={searchTab}
                searchData={searchData}
                setSearchData={setSearchData}
                onSearch={onSearch}
              />
              <div className="FilterSorting">
                {!disableRecordActions && (
                  <span className="FilterList">
                    Filtering {paginationData.total_count} out of {totalRecords}
                  </span>
                )}
                {!isEmpty(searchData.appliedText) && (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchData({
                        text: "",
                        appliedText: "",
                      });

                      getMultipleRssLinks({ search: "" });
                      searchTab(false);
                    }}
                    className="clearBtn"
                  >
                    Clear all
                  </span>
                )}
              </div>
            </div>
          </div>

          {filter && (
            <>
              <div className="FilterMainWrap">
                <div className="FilterBgWrap">
                  <div className="FilterTitleRow">
                    <div className="leftWrap">
                      <h3>Filter</h3>
                    </div>
                    <img
                      onClick={() => filterTab(false)}
                      src={CloseIcon}
                      alt="closeIcon"
                    />
                  </div>
                  <div className="FilterCheckList">
                    <div className="row"></div>
                  </div>
                </div>
              </div>
            </>
          )}
          <InfiniteScroll
            dataLength={rssLinks.length}
            next={fetchMoreData}
            hasMore={
              paginationData?.page !== paginationData?.total_page &&
              paginationData?.total_page !== 0
            }
            loader={
              <div className="infinite-loading-loader">
                <Loader />
              </div>
            }
            style={{ overflow: "none" }}
          >
            <BasicTable
              title="Rss Links"
              header={headers}
              editRouting={`/post/add-${type}-rss`}
              data_keys={data_keys}
              data={rssLinks}
              subData={false}
              confirmDelete={confirmDelete}
              exportOption={false}
            />
          </InfiniteScroll>
        </>
      )}
    </>
  );
};

export default MultipleRssLinks;
