import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import ButtonCustom from "../../components/Button/Button";

const CommonFilterFn = ({ isDisabled = false, ...props }) => {
  const [redirect, setRedirect] = useState(false);

  return (
    <>
      {redirect ? (
        <Redirect
          to={{
            pathname: props.redirectUrl,
            search: props.type ? "type=" + props.type : undefined,
          }}
        />
      ) : (
        ""
      )}

      <ButtonCustom
        noCapital={props.noCapital ?? false}
        onClick={() => {
          setRedirect(true);
        }}
        isDisabled={isDisabled}
        className={props.className}
      >
        {props.buttonText}
      </ButtonCustom>
    </>
  );
};

export default CommonFilterFn;
