import React from "react";

import ButtonCustom from "./components/Button/Button";

import errorimage from "./assets/svg/error404.svg";

class ErrorBoundary extends React.Component {
  render() {
    return (
      <div className="error-content">
        <div>
          <h2>
            Woops!
            <br />
            Looks like something went wrong.
          </h2>
          <div className="img-wrap">
            <img src={errorimage} alt="error" />
          </div>
          <h3>We’ll point you back to where you were.</h3>
          <ButtonCustom
            onClick={() => {
              window.location.reload();
            }}
          >
            Back to previous
          </ButtonCustom>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
