import { isNull } from "lodash";
import { isEmpty } from "lodash";
import moment from "moment";
import { REHYDRATE } from "redux-persist";

let initState = {
  user: {},
  isSuperAdmin: false,
  isSchool: false,
  isSchoolAdmin: false,
  isGeneralAdvisor: false,
  isLogin: false,
  selectedSchool: {},
  refreshSchoolCount: 0,
  selectedPortal: "career",
  careerAdminAccess: true,
  admissionsAdminAccess: true,
  featurePermissions: {},
  featurePermissionsExpiry: null,
  sso: false,
  firebaseToken: null,
  savedRoute: {},
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      const isSuperAdmin = action.res.user_type === "super_admin";
      const careerAdminAccess =
        isSuperAdmin ||
        (action.res.admin_permission &&
        !isNull(action.res.admin_permission) &&
        !isEmpty(action.res.admin_permission)
          ? true
          : false);
      const admissionsAdminAccess =
        isSuperAdmin ||
        (action.res.admission_admin_permission &&
        !isNull(action.res.admission_admin_permission) &&
        !isEmpty(action.res.admission_admin_permission)
          ? true
          : false);

      return {
        ...state,
        user: action.res,
        careerAdminAccess,
        admissionsAdminAccess,
        selectedPortal:
          admissionsAdminAccess && !careerAdminAccess ? "admission" : "career",
        isSuperAdmin,
        isSchool: action.res.user_type === "school",
        isSchoolAdmin: action.res.user_type === "school_admin",
        isGeneralAdvisor: action.res.user_type === "general_advisor",
        isAdminAmbassador:
          action.res.user_type === "school_admin" && admissionsAdminAccess,
        isLogin: true,
        featurePermissions: action.res.feature_permission,
        featurePermissionsExpiry: moment()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm"),
      };

    case "UPDATE_USER_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.res,
          authentication_token: state.user.authentication_token,
        },
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.res,
          auth_token_expiration: action.res.user.auth_token_expiration,
          authentication_token: action.res.user.authentication_token,
        },
      };

    case "UPDATE_FEATURE_PERMISSIONS":
      return {
        ...state,
        featurePermissions: { ...action.res },
        featurePermissionsExpiry: moment()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm"),
      };

    case "RESET_USER_DATA":
      return {
        ...initState,
      };

    case "UPDATE_SELECTED_SCHOOL":
      return {
        ...state,
        selectedSchool: action.res,
      };

    case "REFRESH_SCHOOL_LIST":
      return {
        ...state,
        refreshSchoolCount: state.refreshSchoolCount + 1,
      };

    case "UPDATE_SSO_STATUS":
      return {
        ...state,
        sso: action.res,
      };

    case "UPDATE_SELECTED_PORTAL":
      return {
        ...state,
        selectedPortal: action.res,
      };

    case "SET_FIREBASE_TOKEN":
      return {
        ...state,
        firebaseToken: action.res,
      };

    case "SET_SAVED_ROUTE":
      return {
        ...state,
        savedRoute: action.res,
      };

    case "WIPEOUT_SAVED_ROUTE":
      return {
        ...state,
        savedRoute: {},
      };

    case REHYDRATE:
      return {
        ...state,
        ...(action.payload && action.payload.loginReducer
          ? action.payload.loginReducer
          : initState),
      };
    default:
      return {
        ...state,
      };
  }
};
export default loginReducer;
