//Manage Features

export const GetPermissionsForSchool = {
  url: "admin/school_module_permissions",
  method: "GET",
};

export const UpdatePermissionsForSchool = {
  url: "admin/school_module_permissions/update_module_permissions",
  method: "PUT",
};

export const GetPermissionsForLoggedUser = {
  url: "school_module_permissions",
  method: "GET",
};

export const GetPermissionsForSchoolAdmissions = {
  url: "admission_portal/admin/school_module_permissions",
  method: "GET",
};

export const UpdatePermissionsForSchoolAdmissions = {
  url: "admission_portal/admin/school_module_permissions/update_module_permissions",
  method: "PUT",
};

export const SetCustomFields = {
  url: "admin/schools/set_custom_fields",
  method: "POST",
};
