import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Bugsnag from "@bugsnag/js";

import App from "./App";
import config from "./config";
import AppContext from "./context/context";
import ErrorWrapper from "./ErrorWrapper";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import WebSocketProvider from "./webSocket/WebSocketProvider";

import "./index.css";

try {
  if (process.env.REACT_APP_ENV === "prod") console.log = () => {};
} catch (error) {
  // Allow only error logs
  console.error(error);
}

Bugsnag.start({
  apiKey: config().BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});
const BugSnagErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <BugSnagErrorBoundary FallbackComponent={ErrorWrapper}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContext.Provider
            value={{
              name: "demo",
            }}
          >
            <App />
          </AppContext.Provider>
          <WebSocketProvider />
        </PersistGate>
      </Provider>
    </BugSnagErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
