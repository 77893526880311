import React from "react";
import { AddRSSOption } from "./pages/Update/RssFeeds/index";
import CommunitySuggestions from "./pages/Engage/CommunitySuggestions/index.js";

const Dashboard = React.lazy(() => import("./pages/Dashboard/dashboard.js"));

const InterstrideTeam = React.lazy(() =>
  import("./pages/ManageUsers/InterstrideTeam/index")
);
const InterstrideAddTeam = React.lazy(() =>
  import("./pages/ManageUsers/InterstrideTeam/add")
);

const Schools = React.lazy(() => import("./pages/ManageUsers/Schools/index"));
const AddSchools = React.lazy(() =>
  import("./pages/ManageUsers/Schools/add.js")
);

const Keywords = React.lazy(() => import("./pages/ManageUsers/Keywords/index"));
const AddKeywords = React.lazy(() =>
  import("./pages/ManageUsers/Keywords/add.js")
);

const Alumni = React.lazy(() => import("./pages/ManageUsers/Alumni/index"));
const EditPendingLead = React.lazy(() =>
  import("./pages/ManageUsers/Alumni/pendingLead")
);
const PendingRequest = React.lazy(() =>
  import("./pages/ManageUsers/PendingRequests")
);

const Events = React.lazy(() => import("./pages/Post/Webinars/index"));
const AddEvents = React.lazy(() => import("./pages/Post/Webinars/add"));
const AddEventCategory = React.lazy(() =>
  import("./pages/Post/Webinars/addCategory")
);

const StudentServices = React.lazy(() =>
  import("./pages/Post/StudentServices/index")
);
const AddStudentServices = React.lazy(() =>
  import("./pages/Post/StudentServices/add")
);

const AddPromotion = React.lazy(() => import("./pages/Post/Promote/add"));
const Promotions = React.lazy(() => import("./pages/Post/Promote/index"));

const ListKits = React.lazy(() => import("./pages/Engage/Kits/index"));

const AddKits = React.lazy(() => import("./pages/Engage/Kits/add.js"));

const StudentFeedback = React.lazy(() =>
  import("./pages/Engage/StudentFeedback/index")
);

const HomeFeed = React.lazy(() => import("./pages/Post/HomeFeed/index"));
const AddHomeFeed = React.lazy(() => import("./pages/Post/HomeFeed/add"));
const EditHomeFeed = React.lazy(() => import("./pages/Post/HomeFeed/add"));

const Resources = React.lazy(() => import("./pages/Post/Resources/index"));
const AddResources = React.lazy(() => import("./pages/Post/Resources/add"));
const AddCategoryResources = React.lazy(() =>
  import("./pages/Post/Resources/addCategory")
);

const MyProfile = React.lazy(() => import("./pages/MyProfile/index"));

const ChangePassword = React.lazy(() =>
  import("./pages/MyProfile/ChangePassword/index")
);
const Notification = React.lazy(() =>
  import("./pages/NotificationV2/Notification")
);
const NotificationSettings = React.lazy(() =>
  import("./pages/NotificationV2/NotificationSettings")
);
const InterstrideAdmin = React.lazy(() =>
  import("./pages/ManageUsers/Admin/index")
);
const InterstrideAddAdmin = React.lazy(() =>
  import("./pages/ManageUsers/Admin/add")
);

const InterstrideUser = React.lazy(() =>
  import("./pages/ManageUsers/Users/index")
);
const InterstrideAddUsers = React.lazy(() =>
  import("./pages/ManageUsers/Users/add")
);
const InterstrideAddAlumni = React.lazy(() =>
  import("./pages/ManageUsers/Alumni/addAlumni")
);

const InterstrideAdvisors = React.lazy(() =>
  import("./pages/ManageUsers/Advisors/index")
);
const InterstrideAddAdvisors = React.lazy(() =>
  import("./pages/ManageUsers/Advisors/add")
);

const StaffAndFaculty = React.lazy(() =>
  import("./pages/ManageUsers/StaffAndFaculty/index")
);

const Leades = React.lazy(() => import("./pages/ManageUsers/Leads/index"));
const AddLeads = React.lazy(() => import("./pages/ManageUsers/Leads/add"));
const Users = React.lazy(() => import("./pages/ManageUsers/Users"));

const Jobs = React.lazy(() => import("./pages/Update/JobAndInternship/index"));
const AddJobs = React.lazy(() => import("./pages/Update/JobAndInternship/add"));
const AddRssFeed = React.lazy(() => import("./pages/Update/RssFeeds/add"));
const ManageRssFeed = React.lazy(() => import("./pages/Update/RssFeeds/index"));

const Country = React.lazy(() => import("./pages/Update/Country/index"));
const EditCountry = React.lazy(() => import("./pages/Update/Country/add"));
const AddCountryCity = React.lazy(() =>
  import("./pages/Update/Country/addCity")
);

const Guidance = React.lazy(() =>
  import("./pages/Update/CareerGameplan/Guidance/index")
);
const AddGuidance = React.lazy(() =>
  import("./pages/Update/CareerGameplan/Guidance/add")
);

const CheckLists = React.lazy(() => import("./pages/Update/CheckLists/index"));
const AddChecklistCategory = React.lazy(() =>
  import("./pages/Update/CheckLists/add")
);

const Topics = React.lazy(() => import("./pages/Communication/Topics/index"));
const DiscoverTopic = React.lazy(() =>
  import("./pages/Communication/Topics/Discover/index")
);
const AddTopic = React.lazy(() => import("./pages/Communication/Topics/add"));
const ManageTopic = React.lazy(() =>
  import("./pages/Communication/Topics/Manage/index.js")
);

const HelpCenter = React.lazy(() => import("./pages/HelpCenter/HelpCenter"));
const Appointment = React.lazy(() => import("./pages/Book-Appointment/index"));
const Zoom = React.lazy(() => import("./pages/Book-Appointment/Zoom/index"));
const AddAppointment = React.lazy(() => import("./pages/Book-Appointment/add"));
const SetAvailabalityHours = React.lazy(() =>
  import("./pages/Book-Appointment/SetAvailabalityHours")
);
const SetCalendar = React.lazy(() =>
  import("./pages/Book-Appointment/SetCalendar")
);
const AppointmentSetting = React.lazy(() =>
  import("./pages/Book-Appointment/AppointmentSetting")
);

const ManageFeatures = React.lazy(() =>
  import("./pages/Manage/Features/index")
);

const ManageVersions = React.lazy(() =>
  import("./pages/Manage/Versions/index")
);

const Analytics = React.lazy(() => import("./pages/Engage/Analytics/index.js"));

/** Admissions Routes Import - Start */
const Admissions_Dashboard = React.lazy(() =>
  import("./pages_admissions/Dashboard/dashboard")
);

const Admissions_Webinars = React.lazy(() =>
  import("./pages_admissions/Post/Webinars/index")
);

const Admissions_Admins = React.lazy(() =>
  import("./pages_admissions/ManageUsers/Admin/index")
);

const Admissions_Prospect = React.lazy(() =>
  import("./pages_admissions/ManageUsers/Prospects/index")
);
const Admissions_AddProspect = React.lazy(() =>
  import("./pages_admissions/ManageUsers/Prospects/add")
);

const Admissions_StudentAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/StudentAmbassadors/index")
);
const Admissions_AddStudentAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/StudentAmbassadors/add")
);

const Admissions_Engagement_Details = React.lazy(() =>
  import("./pages_admissions/Stats/Engagement/details")
);

const Admissions_AlumniAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/AlumniAmbassadors/index")
);
const Admissions_AddAlumniAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/AlumniAmbassadors/add")
);
const Admissions_StaffAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/StaffAmbassadors/index")
);
const Admissions_AddStaffAmbassadors = React.lazy(() =>
  import("./pages_admissions/ManageUsers/StaffAmbassadors/add")
);

const Admissions_Resources = React.lazy(() =>
  import("./pages_admissions/Post/Resources/index")
);
const Admissions_Student_Services = React.lazy(() =>
  import("./pages_admissions/Post/StudentServices/index")
);
const Admissions_Campus_Map = React.lazy(() =>
  import("./pages_admissions/CampusMap/CampusMap.jsx")
);
const Admissions_Campus_Map_Add_Category = React.lazy(() =>
  import("./pages_admissions/CampusMap/AddCategory/AddCategory.jsx")
);
const Admissions_Campus_Map_Add_Place = React.lazy(() =>
  import("./pages_admissions/CampusMap/AddPlace/AddPlace.jsx")
);
const Admissions_Checklists = React.lazy(() =>
  import("./pages_admissions/Post/CheckLists/index")
);
const AddChecklistCategoryAdmission = React.lazy(() =>
  import("./pages_admissions/Post/CheckLists/add")
);
const Admissions_Topics = React.lazy(() =>
  import("./pages_admissions/Topics/index")
);
const AddTopicAdmissions = React.lazy(() =>
  import("./pages_admissions/Topics/add")
);

const Admissions_Messages_Index = React.lazy(() =>
  import("./pages_admissions/Messages/index")
);
const Admissions_Messages_New_Message = React.lazy(() =>
  import("./pages_admissions/Messages/NewMessage/NewMessage")
);
const Admissions_Messages_New_Group = React.lazy(() =>
  import("./pages_admissions/Messages/NewGroup/NewGroup")
);
const Admissions_Homefeed = React.lazy(() =>
  import("./pages_admissions/Post/HomeFeed/index")
);
const Admissions_Stats = React.lazy(() =>
  import("./pages_admissions/Stats/index")
);
const Admissions_Stats_TrackSignUps = React.lazy(() =>
  import("./pages_admissions/Stats/TrackSignUps/index.js")
);
const Admissions_Stats_AddUTM = React.lazy(() =>
  import("./pages_admissions/Stats/TrackSignUps/add.js")
);
const Admmissions_ManageFeatures = React.lazy(() =>
  import("./pages_admissions/Manage/Features/index")
);
const Admissions_Employment_Data = React.lazy(() =>
  import("./pages_admissions/OptData/index.jsx")
);
const Admissions_Employment_Data_Import = React.lazy(() =>
  import("./pages_admissions/OptData/add/add.jsx")
);
const Admissions_Employment_Data_Edit = React.lazy(() =>
  import("./pages_admissions/OptData/edit/edit.jsx")
);

const ManageSignUp = React.lazy(() =>
  import("./pages_admissions/Manage/SignUp/index.js")
);

/** Admissions Routes Import - End */

const routes = [
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
  },
  {
    path: "/notification-settings",
    name: "NotificationSettings",
    component: NotificationSettings,
  },
  {
    path: "/communication/topics",
    name: "Communities",
    component: Topics,
  },
  {
    path: "/communication/add-topic",
    name: "Schools",
    addType: true,
    addFormText: "Create new Community",
    editFormText: "Edit Community",
    backUrlText: "Back to Communities",
    backUrl: "/communication/topics",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: AddTopic,
  },
  {
    path: "/communication/manage-topic",
    name: "Communities Manage",
    component: ManageTopic,
  },
  {
    path: "/communication/discover-topic",
    name: "Discover",
    addFormText: "Discover",
    backUrlText: "Back to Communities",
    backUrl: "/communication/topics",
    component: DiscoverTopic,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    addFormText: "New Post",
    backUrlText: "Back to Home Feed",
    component: Dashboard,
  },
  {
    path: "/manage-user/team",
    name: "InterstrideTeam",
    addFormText: "New Webinar",
    backUrlText: "Back to Webinars",
    component: InterstrideTeam,
  },
  {
    path: "/manage-user/add-team",
    addType: true,
    name: "InterstrideAddTeam",
    addFormText: "Create New Member",
    editFormText: "Edit Member",
    backUrlText: "Back to Interstride Team",
    backUrl: "/manage-user/team",
    component: InterstrideAddTeam,
  },
  {
    path: "/manage-user/admin",
    name: "InterstrideAdmin",
    component: InterstrideAdmin,
  },
  {
    path: "/manage-user/add-admin",
    addType: true,
    name: "InterstrideAddAdmin",
    addFormText: "New Admin",
    editFormText: "Edit Admin",
    backUrlText: "Back to Admins",
    backUrl: "/manage-user/admin",
    dynamicFormSteps: true,
    stepType: true,
    totalStepCount: 2,
    step: 1,
    component: InterstrideAddAdmin,
  },
  {
    path: "/manage-user/user",
    name: "InterstrideUser",
    component: InterstrideUser,
  },
  {
    path: "/manage-user/add-user",
    addType: true,
    name: "InterstrideAddUsers",
    addFormText: "Invite Students",
    backUrlText: "Back to Students",
    backUrl: "/manage-user/user",
    component: InterstrideAddUsers,
    totalStepCount: 1,
    step: 1,
  },
  {
    path: "/manage-user/add-alumni",
    addType: true,
    name: "InterstrideAddAlumni",
    addFormText: "Invite Alumni",
    backUrlText: "Back to Alumni",
    backUrl: "/manage-user/alumni",
    component: InterstrideAddAlumni,
  },
  {
    path: "/appointment/advisors",
    name: "InterstrideAdvisors",
    component: InterstrideAdvisors,
  },
  {
    path: "/appointment/add-advisors",
    addType: true,
    name: "InterstrideAddAdvisors",
    addFormText: "Invite advisor",
    editFormText: "Edit Invitation",
    backUrlText: "Back to Invitees",
    backUrl: "/appointment/advisors",
    totalStepCount: 1,
    step: 1,
    stepType: false,
    component: InterstrideAddAdvisors,
  },
  {
    path: "/manage-user/staff-and-faculty",
    name: "StaffAndFaculty",
    component: StaffAndFaculty,
  },
  {
    path: "/manage-user/schools",
    name: "Schools",
    component: Schools,
  },
  {
    path: "/manage-user/add-schools",
    name: "Schools",
    addType: true,
    addFormText: "Create New School",
    editFormText: "Edit School",
    backUrlText: "Back to schools",
    backUrl: "/manage-user/schools",
    totalStepCount: 5,
    step: 1,
    stepType: true,
    component: AddSchools,
  },

  {
    path: "/manage-user/keywords",
    name: "Keywords",
    addFormText: "Create New Keyword",
    backUrlText: "Back to Keywords",
    component: Keywords,
  },
  {
    path: "/manage-user/add-keyword",
    name: "Keywords",
    addType: true,
    addFormText: "Create New Keyword",
    editFormText: "Edit Keyword",
    backUrlText: "Back to Keywords",
    backUrl: "/manage-user/keywords",
    totalStepCount: 1,
    step: 1,
    stepType: false,
    component: AddKeywords,
  },

  {
    path: "/post/home-feed",
    name: "HomeFeed",
    addFormText: "New Post",
    backUrlText: "Back to Home Feed",
    component: HomeFeed,
  },
  {
    path: "/post/events",
    name: "Events",
    addFormText: "New Post",
    backUrlText: "Back to Home Feed",
    component: Events,
  },
  {
    path: "/post/add-events",
    name: "AddEvents",
    addType: true,
    addFormText: "Create New Event",
    editFormText: "Edit Event",
    backUrlText: "Back to Webinars & Events",
    backUrl: "/post/events",
    backUrlAdmission: "/admissions/post/webinars",
    component: AddEvents,
    totalStepCount: 4,
    step: 1,
    stepType: true,
    saveButtonText: "Finalize",
  },
  // HIDDEN TILL FACELIFT V2
  // {
  //   path: "/post/manage-categories",
  //   name: "ManageCategories",
  //   backUrl: "/post/events",
  //   backUrlText: "Back to Webinar",
  //   backUrlAdmission: "/admissions/post/events",
  //   component: ManageCategories,
  //   addType: true,
  //   addFormText: "Manage categories",
  //   customOption: HeaderCustomOption(),
  //   displaySaveButton: false,
  //   hideCancelButton: true,
  //   displaySteps: false,
  //   disableActions: true,
  // },
  {
    path: "/post/add-event-category",
    name: "AddEventCategory",
    addType: true,
    addFormText: "Create new category",
    backUrlText: "Back to Categories",
    backUrl: "/post/events",
    backUrlAdmission: "/admissions/post/webinars",
    component: AddEventCategory,
  },
  {
    path: "/post/add-home-feed",
    name: "HomeFeed",
    addType: true,
    addFormText: "New Post",
    backUrlText: "Back to Home Feed",
    backUrl: "/post/home-feed",
    backUrlAdmission: "/admissions/post/home-feed",
    totalStepCount: 4,
    step: 1,
    stepType: true,
    component: AddHomeFeed,
    saveButtonText: "Finalize",
  },
  {
    path: "/post/resources",
    name: "Resources",
    addFormText: "Create New Resource",
    backUrlText: "Back to Categories",
    component: Resources,
  },
  {
    path: "/post/add-resources",
    name: "Resources",
    addType: true,
    addFormText: "Create New Resource",
    editFormText: "Edit Resource",
    backUrlText: "Back to Categories",
    backUrl: "/post/resources",
    backUrlAdmission: "/admissions/post/resources",
    totalStepCount: 4,
    step: 1,
    stepType: true,
    component: AddResources,
    saveButtonText: "Finalize",
  },

  {
    path: "/post/add-category",
    name: "Resources",
    addType: true,
    addFormText: "New Category",
    backUrlText: "Back to Categories",
    backUrl: "/post/resources",
    backUrlAdmission: "/admissions/post/resources",
    editFormText: "Edit Category",
    component: AddCategoryResources,
  },
  {
    path: "/post/add-student-service-category",
    name: "Student-Service",
    addType: true,
    addFormText: "New Category",
    backUrlText: "Back to Categories",
    backUrl: "/post/resources",
    backUrlAdmission: "/admissions/post/student-services",
    editFormText: "Edit Category",
    component: AddCategoryResources,
  },
  {
    path: "/post/student-services",
    name: "Student-Service",
    addFormText: "New Service",
    backUrlText: "Back to Categories",
    component: StudentServices,
  },
  {
    path: "/post/add-student-services",
    name: "Student-Service",
    addType: true,
    addFormText: "New Service",
    editFormText: "Edit Service",
    backUrlText: "Back to Categories",
    backUrl: "/post/student-services",
    backUrlAdmission: "/admissions/post/student-services",
    totalStepCount: 4,
    step: 1,
    stepType: true,
    component: AddStudentServices,
    saveButtonText: "Finalize",
  },
  {
    path: "/post/add-promotion",
    name: "Promotion",
    addType: true,
    addFormText: "Create a new promotion",
    editFormText: "Edit promotion",
    backUrlText: "Back to Promote",
    backUrl: "/post/promotions",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: AddPromotion,
  },
  {
    path: "/post/promotions",
    name: "Promotion",
    component: Promotions,
  },
  { path: "/manage-user/alumni", name: "Alumni", component: Alumni },
  {
    path: "/manage-user/pending-requests",
    name: "Pending-Requests",
    component: PendingRequest,
  },
  {
    path: "/manage-user/edit-pending-lead",
    name: "Leads",
    addType: true,
    addFormText: "Edit Pending Request",
    editFormText: "Edit Pending Requests",
    backUrlText: "Back to Pending Requests",
    backUrl: "/manage-user/pending-requests",
    totalStepCount: 1,
    step: 1,
    stepType: false,
    component: EditPendingLead,
    saveButtonText: "Save",
  },
  { path: "/manage-user/users", name: "Users", component: Users },
  { path: "/manage-user/leads", name: "Leades", component: Leades },
  {
    path: "/manage-user/edit-lead",
    name: "Leads",
    addType: true,
    addFormText: "Edit Lead",
    editFormText: "Edit Lead",
    backUrlText: "Back to Leads",
    backUrl: "/manage-user/leads",
    totalStepCount: 1,
    step: 1,
    stepType: false,
    component: AddLeads,
    saveButtonText: "Save",
    extraButtonText: "Approve",
  },
  {
    path: "/post/edit-home-feed",
    name: "HomeFeed",
    addType: true,
    editFormText: "Edit Post",
    backUrlText: "Back to Home Feed",
    backUrl: "/post/home-feed",
    backUrlAdmission: "/admissions/post/home-feed",
    totalStepCount: 4,
    step: 1,
    stepType: true,
    component: EditHomeFeed,
    saveButtonText: "Finalize",
  },
  {
    path: "/post/resources",
    name: "Resources",
    addFormText: "Create New Resource",
    backUrlText: "Back to Categories",
    component: Resources,
  },
  {
    path: "/post/add-resources",
    name: "Resources",
    addType: true,
    addFormText: "Create New Resource",
    backUrlText: "Back to Categories",
    backUrl: "/post/resources",
    totalStepCount: 4,
    step: 1,
    stepType: true,
    component: AddResources,
  },
  {
    path: "/post/student-services",
    name: "Student-Service",
    addFormText: "New Service",
    backUrlText: "Back to Categories",
    component: StudentServices,
  },
  {
    path: "/engage/studentfeedback",
    name: "Student Feedback",
    component: StudentFeedback,
    exact: true,
  },
  {
    path: "/engage/adminfeedback",
    name: "Admin Feedback",
    component: StudentFeedback,
    exact: true,
  },
  {
    path: "/engage/community-suggestions",
    name: "Community Suggestions",
    component: CommunitySuggestions,
    exact: true,
  },
  { path: "/manage-user/leads", name: "Leads", component: Leades },

  { path: "/my-profile", name: "My Profile", component: MyProfile },
  {
    path: "/change-password",
    name: "Change Password",
    showHeader: true,
    component: ChangePassword,
    backUrlText: "Back to Profile",
    backUrl: "/my-profile",
  },
  {
    path: "/chat/messages",
    name: "Messages",
    component: Admissions_Messages_Index,
  },
  {
    path: "/chat/add-message",
    name: "New Message",
    addType: true,
    addFormText: "Create New Message",
    backUrlText: "Back to Messages",
    backUrl: "/chat/messages",
    component: Admissions_Messages_New_Message,
  },
  {
    path: "/chat/add-group",
    name: "New Group",
    addType: true,
    addFormText: "Start a new group chat",
    backUrlText: "Back to Messages",
    backUrl: "/chat/messages",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_Messages_New_Group,
  },

  {
    path: "/post/jobsandinternship",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/post/add-jobsandinternship",
    name: "AddJobsandInternship",
    component: AddJobs,
    addType: true,
    addFormText: "Create New Job or Internship",
    editFormText: "Edit Job or Internship",
    backUrlText: "Back to Jobs and Internships",
    backUrl: "/post/jobsandinternship",
    totalStepCount: 3,
    step: 1,
    stepType: true,
  },

  {
    path: "/manage-rss/job",
    name: "ManageRssFeed",
    addType: true,
    component: ManageRssFeed,
    addFormText: "Manage RSS feeds",
    backUrlText: "Back to Jobs and Internships",
    backUrl: "/post/jobsandinternship",
    customOption: AddRSSOption("job"),
    displaySaveButton: false,
    hideCancelButton: true,
    displaySteps: false,
    disableActions: true,
  },

  {
    path: "/manage-rss/event",
    name: "ManageRssFeed",
    addType: true,
    component: ManageRssFeed,
    addFormText: "Manage RSS feeds",
    backUrlText: "Back to Webinars & Events",
    backUrl: "/post/events",
    customOption: AddRSSOption("event"),
    displaySaveButton: false,
    hideCancelButton: true,
    displaySteps: false,
    disableActions: true,
  },

  {
    path: "/manage-rss/post",
    name: "ManageRssFeed",
    component: ManageRssFeed,
    editFormText: "Edit Job RSS",
    backUrlText: "Back to Home Feed",
    backUrl: "/post/home-feed",
  },

  {
    path: "/post/add-job-rss",
    name: "AddRssFeed",
    component: AddRssFeed,
    addType: true,
    addFormText: "Create New Job RSS",
    editFormText: "Edit Job RSS",
    backUrlText: "Back to Manage RSS",
    backUrl: "/manage-rss/job",
  },

  {
    path: "/post/add-event-rss",
    name: "AddRssFeed",
    addType: true,
    addFormText: "Create New event RSS",
    editFormText: "Edit event RSS",
    backUrlText: "Back to Manage RSS",
    component: AddRssFeed,
    backUrl: "/manage-rss/post",
  },
  {
    path: "/post/add-post-rss",
    name: "AddRssFeed",
    addType: true,
    addFormText: "Create New Home Feed RSS",
    editFormText: "Edit Home Feed RSS",
    backUrlText: "Back to Home Feed",
    backUrl: "/manage-rss/post",
    component: AddRssFeed,
  },
  {
    path: "/post/country",
    name: "Country",
    component: Country,
  },

  {
    path: "/post/edit-country",
    name: "Edit Country",
    addType: true,
    editFormText: "Edit Country Insights",
    backUrlText: "Back to Country Insights",
    backUrl: "/post/country",
    component: EditCountry,
  },
  {
    path: "/post/add-country-city",
    name: "Country City",
    addType: true,
    addFormText: "New City",
    editFormText: "Edit City",
    backUrlText: "Back to Country Insights",
    backUrl: "/post/country",
    showSkip: false,
    component: AddCountryCity,
  },

  {
    path: "/post/checklists",
    name: "CheckLists",
    component: CheckLists,
  },
  {
    path: "/post/add-checklists-category",
    name: "CheckLists",
    addType: true,
    addFormText: "Create New Category",
    editFormText: "Edit Category",
    backUrlText: "Back to Checklists",
    backUrl: "/post/checklists",
    showSkip: false,
    component: AddChecklistCategory,
  },
  {
    path: "/post/career-gameplan/guidance",
    name: "Career Gameplan",
    component: Guidance,
  },

  {
    path: "/post/career-gameplan/add-guidance",
    name: "Guidance",
    addType: true,
    addFormText: "Create New Tip",
    editFormText: "Edit Tip",
    backUrlText: "Back to Guidance",
    backUrl: "/post/career-gameplan/guidance",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: AddGuidance,
  },
  {
    path: "/help-center",
    name: "HelpCenter",
    component: HelpCenter,
  },
  {
    path: "/manage/features",
    name: "Manage Features",
    component: ManageFeatures,
  },
  {
    path: "/manage/versions",
    name: "Manage Version",
    component: ManageVersions,
  },
  {
    path: "/appointment",
    name: "BOOK_APPOINTNMENT",
    component: Appointment,
    exact: true,
  },
  {
    path: "/appointment/zoom",
    name: "ZOOM_CONNECT",
    component: Zoom,
    exact: true,
  },
  {
    path: "/appointment/add-appointment",
    name: "Create Appointment",
    addType: true,
    addFormText: "New Meeting",
    editFormText: "Edit Appointment",
    backUrlText: "Back to Calendar",
    backUrl: "/appointment",
    totalStepCount: 3,
    step: 1,
    stepType: true,
    component: AddAppointment,
  },
  {
    path: "/appointment/set-hours",
    name: "Set Hours",
    addType: true,
    addFormText: "Availability",
    component: SetAvailabalityHours,
    backUrlText: "Back to Calendar",
    totalStepCount: 3,
    step: 2,
    backUrl: "/appointment",
  },
  {
    path: "/appointment/set-calendar",
    name: "Set Calendar",
    addType: true,
    saveButtonText: "Skip",
    component: SetCalendar,
    addFormText: "Sync Calendars",
    editFormText: "Sync Calendars",
    backUrlText: "Back to Calendar",
    totalStepCount: 3,
    showSkip: false,
    step: 3,
    backUrl: "/appointment",
    showHeader: true,
    hideCancelButton: true,
  },
  {
    path: "/appointment/settings",
    name: "Appointment Setting",
    addType: true,
    component: AppointmentSetting,
    addFormText: "Settings",
    editFormText: "Settings",
    backUrlText: "Back to Calendar",
    totalStepCount: 3,
    step: 1,
    backUrl: "/appointment",
  },
  {
    path: "/post/employment-data",
    name: "Employment Data",
    component: Admissions_Employment_Data,
  },
  {
    path: "/post/employment-data-import",
    name: "Import Data",
    addType: true,
    addFormText: "Import Data",
    backUrlText: "Back to Employment Data",
    backUrl: "/post/employment-data",
    component: Admissions_Employment_Data_Import,
  },
  {
    path: "/post/employment-data-edit",
    name: "Import Data",
    addType: true,
    editFormText: "Edit Employment Data Record",
    backUrlText: "Back to Employment Data",
    backUrl: "/post/employment-data",
    component: Admissions_Employment_Data_Edit,
  },
  {
    path: "/engage/analytics",
    name: "Analytics",
    component: Analytics,
  },

  /** Admissions Routes - Start */
  {
    path: "/admissions/dashboard",
    name: "Dashboard",
    component: Admissions_Dashboard,
  },
  {
    path: "/admissions/post/webinars",
    name: "Webinars",
    component: Admissions_Webinars,
  },
  {
    path: "/admissions/manage-user/admin",
    name: "Admins",
    component: Admissions_Admins,
  },
  {
    path: "/admissions/manage-user/add-admin",
    addType: true,
    name: "InterstrideAddAdmin",
    addFormText: "New Admin",
    editFormText: "Edit Admin",
    backUrlText: "Back to Admins",
    backUrl: "/admissions/manage-user/admin",
    dynamicFormSteps: true,
    stepType: true,
    totalStepCount: 2,
    step: 1,
    component: InterstrideAddAdmin,
  },
  {
    path: "/admissions/manage-user/prospects",
    name: "Prospects",
    component: Admissions_Prospect,
  },
  {
    path: "/admissions/manage-user/add-prospect",
    name: "Student Ambassadors",
    addType: true,
    addFormText: "Invite Prospect",
    backUrlText: "Back to Prospects",
    backUrl: "/admissions/manage-user/prospects",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_AddProspect,
  },
  {
    path: "/admissions/manage-user/student-ambassadors",
    name: "Student Ambassadors",
    component: Admissions_StudentAmbassadors,
  },
  {
    path: "/admissions/manage-user/add-student-ambassador",
    name: "Student Ambassadors",
    addType: true,
    addFormText: "Invite Students",
    backUrlText: "Back to Student Ambassadors",
    backUrl: "/admissions/manage-user/student-ambassadors",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_AddStudentAmbassadors,
  },
  {
    path: "/admissions/stats/statistics/engagement-details/:id?",
    name: "Student Ambassadors",
    backUrlText: "Back to Engagement",
    backUrl: "/admissions/stats/statistics?tab=Engagement",
    component: Admissions_Engagement_Details,
  },
  {
    path: "/admissions/manage-user/alumni-ambassadors",
    name: "Alumni Ambassadors",
    component: Admissions_AlumniAmbassadors,
  },
  {
    path: "/admissions/manage-user/add-alumni-ambassador",
    name: "Alumni Ambassadors",
    addType: true,
    addFormText: "Invite Alumni",
    backUrlText: "Back to Alumni Ambassadors",
    backUrl: "/admissions/manage-user/alumni-ambassadors",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_AddAlumniAmbassadors,
  },
  {
    path: "/admissions/manage-user/staff-ambassadors",
    name: "Staff Ambassadors",
    component: Admissions_StaffAmbassadors,
  },
  {
    path: "/admissions/manage-user/add-staff-ambassador",
    name: "Staff Ambassadors",
    addType: true,
    addFormText: "Invite Staff",
    backUrlText: "Back to Staff Ambassadors",
    backUrl: "/admissions/manage-user/staff-ambassadors",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_AddStaffAmbassadors,
  },
  {
    path: "/admissions/post/resources",
    name: "Resources",
    component: Admissions_Resources,
  },
  {
    path: "/admissions/post/student-services",
    name: "Resources",
    component: Admissions_Student_Services,
  },
  {
    path: "/admissions/post/campus-map",
    name: "Campus-Map",
    component: Admissions_Campus_Map,
  },
  {
    path: "/admissions/post/campus-map-add-category",
    name: "Campus-Map-Add-Category",
    addType: true,
    addFormText: "New category",
    backUrlText: "Back to places",
    backUrl: "/admissions/post/campus-map",
    editFormText: "Edit category",
    component: Admissions_Campus_Map_Add_Category,
  },
  {
    path: "/admissions/post/campus-map-add-place",
    name: "Campus-Map-Add-Place",
    addType: true,
    addFormText: "New place",
    backUrlText: "Back to places",
    backUrl: "/admissions/post/campus-map",
    editFormText: "Edit category",
    component: Admissions_Campus_Map_Add_Place,
  },
  {
    path: "/admissions/post/checklists",
    name: "Checklists",
    component: Admissions_Checklists,
  },
  {
    path: "/admissions/post/add-checklists-category",
    name: "CheckLists",
    addType: true,
    addFormText: "Create New Category",
    editFormText: "Edit Category",
    backUrlText: "Back to Checklists",
    backUrl: "/admissions/post/checklists",
    showSkip: false,
    component: AddChecklistCategoryAdmission,
  },
  {
    path: "/admissions/chat/topics",
    name: "Community",
    component: Admissions_Topics,
  },
  {
    path: "/admissions/chat/manage-topic",
    name: "Communities Manage",
    component: ManageTopic,
  },
  {
    path: "/admissions/chat/add-topic",
    name: "Community",
    addType: true,
    addFormText: "Create New Community",
    editFormText: "Edit Community",
    backUrlText: "Back to Community",
    backUrl: "/admissions/chat/topics",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: AddTopicAdmissions,
  },
  {
    path: "/admissions/chat/messages",
    name: "Messages",
    component: Admissions_Messages_Index,
  },
  {
    path: "/admissions/chat/add-message",
    name: "New Message",
    addType: true,
    addFormText: "Create New Message",
    backUrlText: "Back to Messages",
    backUrl: "/admissions/chat/messages",
    component: Admissions_Messages_New_Message,
  },
  {
    path: "/admissions/chat/add-group",
    name: "New Group",
    addType: true,
    addFormText: "Start a new group chat",
    backUrlText: "Back to Messages",
    backUrl: "/admissions/chat/messages",
    totalStepCount: 2,
    step: 1,
    stepType: true,
    component: Admissions_Messages_New_Group,
  },
  {
    path: "/admissions/post/home-feed",
    name: "Homefeed",
    component: Admissions_Homefeed,
  },
  {
    path: "/admissions/stats/statistics",
    name: "Stats",
    component: Admissions_Stats,
  },
  {
    path: "/admissions/stats/track-sign-ups",
    name: "Track Sign Ups",
    component: Admissions_Stats_TrackSignUps,
  },
  {
    path: "/admissions/stats/add-utm",
    name: "Add",
    component: Admissions_Stats_AddUTM,
    addType: true,
    addFormText: "New UTM",
    editFormText: "Edit UTM",
    backUrlText: "Back to Track Sign Ups",
    backUrl: "/admissions/stats/track-sign-ups",
  },
  {
    path: "/admissions/manage/features",
    name: "Manage Features",
    component: Admmissions_ManageFeatures,
  },
  {
    path: "/admissions/post/employment-data",
    name: "Career",
    component: Admissions_Employment_Data,
  },
  {
    path: "/admissions/post/employment-data-import",
    name: "Import Data",
    addType: true,
    addFormText: "Import Data",
    backUrlText: "Back to Career",
    backUrl: "/admissions/post/employment-data",
    component: Admissions_Employment_Data_Import,
  },
  {
    path: "/admissions/post/employment-data-edit",
    name: "Import Data",
    addType: true,
    editFormText: "Edit Career Record",
    backUrlText: "Back to Career",
    backUrl: "/admissions/post/employment-data",
    component: Admissions_Employment_Data_Edit,
  },
  {
    path: "/admissions/engage/marketing-kit",
    component: ListKits,
    addType: false,
  },
  {
    path: "/admissions/engage/add-marketing-kit/",
    component: AddKits,
    addType: true,
    backUrlText: "Back to Marketing Kits",
    backUrl: "/admissions/engage/marketing-kit",
  },
  {
    path: "/admissions/engage/prospectfeedback",
    name: "Prospect Feedback",
    component: StudentFeedback,
    exact: true,
  },
  {
    path: "/admissions/engage/adminfeedback",
    name: "Admin Feedback",
    component: StudentFeedback,
    exact: true,
  },
  {
    path: "/engage/marketing-kit/add/:kitID",
    component: AddKits,
    addType: true,
  },
  {
    path: "/engage/marketing-kit/:kitID",
    component: ListKits,
    addType: false,
    exact: false,
  },
  {
    path: "/manage/sign-up",
    name: "Manage SignUp",
    component: ManageSignUp,
  },
  /** Admissions Routes - End */
];

export default routes;
