import axiosInstance from "../config/axiosInterceptors";
import { headers_new_token, headers_with_token } from "../helper/helper";

export const signInAPI = async (
  email,
  password,
  firebaseToken,
  keepSignedIn
) => {
  let requestData = {
    user: { email, password },
    user_device: {
      platform: 302,
      device_name: "",
      device_token: firebaseToken,
      app_version: "",
      os_version: "",
    },
    remember_me: keepSignedIn,
  };
  return await axiosInstance.post("/users/sign_in", requestData).then((res) => {
    return res.data.data;
  });
};

export const getNewToken = async (keepSignedIn) => {
  let rememberMe = "";
  if (keepSignedIn === true) {
    rememberMe = `?remember_me=${keepSignedIn}`;
  }
  const res = await axiosInstance.get(
    `/users/regenerate_token${rememberMe}`,
    headers_new_token()
  );
  return res.data;
};

export const signUpSSO = async (userId, provider, code) => {
  const body = {
    user_id: userId,
    provider: provider,
    code: code,
  };
  return await axiosInstance
    .post(
      "/external_user_accounts/sign_up_external_user_accounts",
      body,
      headers_new_token
    )
    .then((res) => {
      return res.data;
    });
};

export const signInSSO = async (provider, code) => {
  const body = {
    provider: provider,
    code: code,
  };
  return await axiosInstance
    .post("/external_user_accounts/sign_in_external_user_accounts", body)
    .then((res) => {
      return res.data.data;
    });
};

export const disconnectSSO = async (id) => {
  return await axiosInstance
    .delete(
      `/external_user_accounts/disconnect_external_user_account/${id}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data.data;
    });
};

export const userLeadAPI = async (
  email,
  first_name,
  last_name,
  graduation_year,
  major,
  college
) => {
  let requestData = {
    email: email,
    first_name: first_name,
    last_name: last_name,
    graduation_year: graduation_year,
    major: major,
    college: college,
  };
  return await axiosInstance.post("/user_leads", requestData).then((res) => {
    return res.data.data;
  });
};

export const signOutAPI = async (email) => {
  return await axiosInstance.delete("/users/sign_out", {
    data: { email: email },
  });
};

export const resendEmailApi = async (email) => {
  return await axiosInstance
    .post("/users/resend_verification_email", { email: email })
    .then((res) => {
      return res.data;
    });
};

export const forgotPasswordAPI = async (forgotEmail) => {
  return await axiosInstance
    .post("/users/password", { email: forgotEmail })
    .then((res) => {
      return res.data;
    });
};

export const resetPasswordAPI = async (
  password,
  confirm_password,
  rest_token,
  email
) => {
  let requestData = {
    user: {
      password: password,
      password_confirmation: confirm_password,
      reset_password_token: rest_token,
      email: email,
    },
  };
  return await axiosInstance
    .patch("/users/password", requestData)
    .then((res) => {
      return res.data;
    });
};

export const findUserEmailAPI = async (findEmail) => {
  return axiosInstance
    .post("/users/find_user", { email: findEmail })
    .then((res) => {
      return res.data;
    });
};

export const signupNewUser = async (requestData) => {
  return axiosInstance
    .post("/users", requestData, { timeout: 10000 })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return {
        erro_flag: true,
        message: "Server Timeout : No response from server",
      };
    });
};

export const checkSsoUser = async (id) => {
  return axiosInstance
    .post(
      "/external_user_accounts/check_user_exist",
      { id },
      headers_new_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const getUserById = async (userID) => {
  return axiosInstance
    .get(`users/${userID}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const uploadUserResume = async (userID, payload) => {
  return await axiosInstance
    .patch(`users/${userID}`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateUserProfilePic = async (userID, payload) => {
  return await axiosInstance
    .patch(`users/${userID}`, payload, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateUserProfileDetails = async (userID, requestData) => {
  return await axiosInstance
    .patch(`users/${userID}`, requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const removeUserProfilePic = async () => {
  return await axiosInstance
    .get(`users/remove_profile_pic`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateEmailConfirmation = async (values) => {
  const body = {
    email: values.email,
  };
  return await axiosInstance
    .put(`users/update_email_confirmation`, body, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const updateUserEmail = async (email_token, sso = false) => {
  let requestData = {
    email_token,
    sso,
  };
  return await axiosInstance
    .post(`users/update_user_email`, requestData, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const verifyEmailAPI = async (token, email) => {
  return await axiosInstance
    .get(`/verify_email?confirmation_token=${token}&email=${email}`)
    .then((res) => {
      return res.data;
    });
};

export const verifyPassword = async (email, password) => {
  return await axiosInstance
    .get(`/verify_password`, {
      params: {
        email: email,
        password: password,
      },
    })
    .then((res) => {
      return res.data;
    });
};
export const getDepartmentList = async (id) => {
  return await axiosInstance
    .get(`/department_list?school_id=${id}`)
    .then((res) => {
      return res.data;
    });
};

export const leadSchoolsAPI = async (token) => {
  return await axiosInstance
    .get("/user_leads/school_list", { token: token })
    .then((res) => {
      return res.data.data;
    });
};
