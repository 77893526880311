export const AddJob = {
  url: "admin/featured_jobs",
  method: "POST",
};

export const AddRssFeedCareers = {
  url: "admin/rss_feeds",
  method: "POST",
};

export const AddRssFeedAdmissions = {
  url: "admission_portal/admin/rss_feeds",
  method: "POST",
};

export const GetJobsList = {
  url: "admin/featured_jobs",
  method: "GET",
};

export const GetArchiveJobsList = {
  url: "admin/featured_jobs/archived_jobs",
  method: "GET",
};

export const GetJobById = {
  url: "admin/featured_jobs/",
  method: "GET",
};

export const GetRssJobById = {
  url: "admin/rss_feeds/",
  method: "GET",
};

export const GetSchoolRssFeedsCareer = {
  url: "admin/rss_feeds",
  method: "GET",
};

export const GetSchoolRssFeedsAdmissions = {
  url: "admission_portal/admin/rss_feeds",
  method: "GET",
};

export const UpdateJob = {
  url: "admin/featured_jobs/",
  method: "PUT",
};

export const UpdateRssFeedCareers = {
  url: "admin/rss_feeds/",
  method: "PUT",
};

export const UpdateRssFeedAdmissions = {
  url: "admission_portal/admin/rss_feeds/",
  method: "PUT",
};

export const DeleteRssFeedCareers = {
  url: "admin/rss_feeds/",
  method: "DELETE",
};

export const DeleteRssFeedAdmissions = {
  url: "admission_portal/admin/rss_feeds/",
  method: "DELETE",
};

export const DeleteJob = {
  url: "admin/featured_jobs/",
  method: "DELETE",
};

export const ArchiveUnarchiveJob = {
  url: "admin/featured_jobs/",
  method: "PUT",
};

//Country Insights
export const GetCountryInsights = {
  url: "admin/country_insights",
  method: "GET",
};

export const GetCountryInsightById = {
  url: "admin/country_insights/",
  method: "GET",
};

export const UpdateCountryInsights = {
  url: "admin/country_insights/",
  method: "PUT",
};

export const DeleteCountryInsights = {
  url: "admin/country_insights/",
  method: "DELETE",
};

export const GetCityInsightById = {
  url: "city_guides/",
  method: "GET",
};

export const AddCityInsights = {
  url: "city_guides",
  method: "POST",
};

export const UpdateCityInsights = {
  url: "city_guides/",
  method: "PUT",
};

export const DeleteCityInsights = {
  url: "city_guides/",
  method: "DELETE",
};

//Career Gameplan
export const GetGuidanceResourcesList = {
  url: "admin/question_categories/get_question_category_resources",
  method: "GET",
};

export const GetArchivedGuidanceResourcesList = {
  url: "admin/question_category_resources/archived_resource_listing",
  method: "GET",
};

export const GetGuidanceCategories = {
  url: "admin/question_categories",
  method: "GET",
};

export const CreateTip = {
  url: "admin/question_category_resources",
  method: "POST",
};

export const UpdateTip = {
  url: "admin/question_category_resources/",
  method: "PUT",
};

export const GetTipById = {
  url: "admin/question_category_resources/",
  method: "GET",
};

export const ArchiveUnarchiveTip = {
  url: "admin/question_category_resources/archive_unarchive_resource",
  method: "PUT",
};

export const DeleteTip = {
  url: "admin/question_category_resources/",
  method: "DELETE",
};

export const SortTips = {
  url: "admin/question_category_resources/sort_resources",
  method: "PUT",
};
