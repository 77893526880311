export const GetFeedbackFilter = {
  url: "admin/student_feedbacks/filter_data",
  method: "GET",
};

export const GetFeedbackListStudent = {
  url: "admin/student_feedbacks",
  method: "GET",
};

export const GetFeedbackListProspect = {
  url: "admission_portal/admin/prospect_feedbacks",
  method: "GET",
};

export const GetFeedbackListCareerAdmins = {
  url: "admin/admin_feedbacks",
  method: "GET",
};

export const GetFeedbackListAdmissionAdmins = {
  url: "admission_portal/admin/admin_feedbacks",
  method: "GET",
};

export const DeleteFeedbackStudent = {
  url: "admin/student_feedbacks/",
  method: "DELETE",
};

export const DeleteFeedbackAdmin = {
  url: "admin/admin_feedbacks/",
  method: "DELETE",
};

export const DeleteFeedbackProspect = {
  url: "admission_portal/admin/prospect_feedbacks/",
  method: "DELETE",
};

//Kits
export const GetAllKits = {
  url: "admin/kits",
  method: "GET",
};

export const GetAllArchivedKits = {
  url: "admin/kits/archive_kits_listing",
  method: "GET",
};

export const GetKitById = {
  url: "admin/kits/",
  method: "GET",
};

export const AddKit = {
  url: "admin/kits",
  method: "POST",
};

export const EditKit = {
  url: "admin/kits/",
  method: "PUT",
};

export const ArchiveUnacrhiveKit = {
  url: "admin/kits/archive_unarchive_kits",
  method: "PUT",
};

export const DeleteKit = {
  url: "admin/kits/",
  method: "DELETE",
};

export const SortKits = {
  url: "admin/kits/sortable_kits",
  method: "PUT",
};

//Admissions Kits
export const GetAllKitsAdmissions = {
  url: "admission_portal/admin/kits",
  method: "GET",
};

export const GetAllArchivedKitsAdmissions = {
  url: "admission_portal/admin/kits/archive_kits_listing",
  method: "GET",
};

export const GetKitByIdAdmissions = {
  url: "admission_portal/admin/kits/",
  method: "GET",
};

export const AddKitAdmissions = {
  url: "admission_portal/admin/kits",
  method: "POST",
};

export const EditKitAdmissions = {
  url: "admission_portal/admin/kits/",
  method: "PUT",
};

export const ArchiveUnacrhiveKitAdmissions = {
  url: "admission_portal/admin/kits/archive_unarchive_kits",
  method: "PUT",
};

export const DeleteKitAdmissions = {
  url: "admission_portal/admin/kits/",
  method: "DELETE",
};

export const SortKitsAdmissions = {
  url: "admission_portal/admin/kits/sortable_kits",
  method: "PUT",
};

// Analytics
export const GetJobsAnalytics = {
  url: "jobs/analytics",
  method: "GET",
};

export const GetWebinarAnalytics = {
  url: "webinars/analytics",
  method: "GET",
};

export const GetResourceAnalytics = {
  url: "videos/analytics",
  method: "GET",
};

export const GetCareerGameplanAnalytics = {
  url: "gameplan/analytics",
  method: "GET",
};

export const GetCareerGameplanResultsAnalytics = {
  url: "gameplan/records",
  method: "GET",
};

export const GetCommunitySuggestions = {
  url: "admin/topics/suggestions",
  method: "GET",
};
