import React, { useCallback, useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "underscore";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

import "./styles/main.scss";
import useFirebase from "./hooks/useFirebase";
import { addMicrosoftClarity } from "./helper/helper";
import { ROUTES } from "./Constants";
import { fetchRouteParamsFromURL } from "./utils/common";
import { setSavedRoute } from "./redux/action";
import ParentEffect from "./components/ParentEffect/ParentEffect";
import Login from "./pages/Login/login";
import Layout from "./pages/Layout/layout";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NotificationUnsubscribe from "./pages/NotificationUnsubscribe/NotificationUnsubscribe";
import config from "./config";

import "./App.css";
import "./styles/main.scss";

// Injects "Twemoji Country Flags" font and allows us to use this font in the font-family
// css where needed to help us render country flag emojis on chromium based browsers.
// Source: https://github.com/talkjs/country-flag-emoji-polyfill#readme
polyfillCountryFlagEmojis();
const theme = createTheme();

function App() {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.loginReducer);

  const initialEffect = useCallback(() => {
    // Get and save route params if present to be used after login
    (() => {
      const routeParams = fetchRouteParamsFromURL();
      if (routeParams && !isEmpty(routeParams)) {
        dispatch(setSavedRoute(routeParams));
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: config().GOOGLE_TAG_MANAGER_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      addMicrosoftClarity();
    }
    // Microsoft clarity script by Dhaval
    /* if (process.env.REACT_APP_ENV === "prod")
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "do312hx6mm"); */
  }, []);

  useFirebase();

  return (
    <ThemeProvider theme={theme}>
      {/* We need to save initial app load route for further redirection after login, before any of the child components performs any redirection*/}
      <ParentEffect effect={initialEffect} />
      <Router>
        <Switch>
          <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Route
            path={ROUTES.NOTIFICATION_UNSUBSCRIBE}
            component={NotificationUnsubscribe}
          />
          <Route
            path={ROUTES.BASE}
            component={globalState?.isLogin ? Layout : Login}
          />
        </Switch>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
