import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { clone } from "underscore";

import { commonApiFN } from "../../common/api";
import { GetAllNotifications } from "../../common/module_wise_api_url/notifications";
import NotificationItem from "./NotificationItem/NotificationItem";

import CloseIcon from "../../assets/svg/CloseIcon";
import SettingIcon from "../../assets/svg/Setting.svg";
import { IconButton } from "@mui/material";

export default function NotificationsDrawer({ setShowNotificationsDrawer }) {
  let history = useHistory();
  const dispatch = useDispatch();

  const displayNotificationsCount = 3; // Denotes the numbers of notifications to show

  const { unreadNotifications, unreadNotificationCount } = useSelector(
    (store) => store.notificationStore
  );

  const getNotifications = async () => {
    const request = clone(GetAllNotifications);
    const response = await commonApiFN(request);

    if (response.success && response.data) {
      dispatch({
        type: "SET_ALL_NOTIFICATIONS",
        payload: response.data,
      });
    }
  };

  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotificationCount]);

  const displayNotifications = unreadNotifications.slice(
    0,
    displayNotificationsCount
  );

  /**
   * Get the API responses' notification id and update notification store and close the drawer
   * @param {Object} id Notification ID from API: admin/notifications/{notification_id}/read
   */
  const handleDrawerNotificationView = (id) => {
    setShowNotificationsDrawer(false);
    if (id) {
      // Call notification store method
      dispatch({
        type: "DECREMENT_NOTIFICATION_COUNT",
      });
    }
  };

  return (
    <div className="notifications-drawer">
      <div className="notifications-drawer__header">
        <div className="notifications-drawer__header__left">
          <h3>New Notifications</h3>
        </div>
        <div className="notifications-drawer__header__right">
          <a
            href
            className="nlink-secondary"
            onClick={(e) => {
              e.preventDefault();
              setShowNotificationsDrawer(false);
              history.push("/notification");
            }}
          >
            View all
          </a>
          <IconButton
            onClick={() => {
              setShowNotificationsDrawer(false);
              history.push("/notification-settings");
            }}
          >
            <img src={SettingIcon} alt="Settings" />
          </IconButton>
          <CloseIcon
            fill={"#607790"}
            onClick={() => setShowNotificationsDrawer(false)}
          />
        </div>
      </div>
      <div className="notifications-drawer__content">
        {displayNotifications.length === 0 ? (
          <div className="notifications-drawer__content__empty">
            <h4>Looks like you have no unread notifications!</h4>
          </div>
        ) : (
          displayNotifications.map((notification, index) => (
            <NotificationItem
              variant="small"
              notification={notification}
              onViewClick={handleDrawerNotificationView}
              key={`notification-${notification.id}`}
            />
          ))
        )}

        {unreadNotifications &&
          unreadNotifications.length > displayNotificationsCount && (
            <div className="notifications-drawer__content__view-more">
              <span
                onClick={() => {
                  setShowNotificationsDrawer(false);
                  history.push("/notification");
                }}
              >
                {unreadNotifications.length - displayNotifications.length} more
              </span>
            </div>
          )}
      </div>
    </div>
  );
}
