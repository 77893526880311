import { useCallback, useEffect, useReducer, useRef } from "react";

import lodashGet from "lodash/get";
import QuickLRU from "quick-lru";

import mem from "./myMem";

const cache = new QuickLRU({ maxSize: 1000 });

function reducer(state, action) {
  switch (action.type) {
    case "_data": {
      return {
        ...state,
        _data: action.payload,
      };
    }

    case "startFetching":
      return { ...state, _hasFetched: false, _isFetching: true };
    case "error":
      return {
        ...state,
        _hasFetched: true,
        _isFetching: false,
        _error: action.payload,
        _has_errors: true,
      };
    case "success":
      return {
        ...state,
        _hasFetched: true,
        _isFetching: false,
        _data: action.payload,
        _error: undefined,
        _has_errors: false,
      };
    default:
      return state;
  }
}

let initialState = {
  _hasFetched: false,
  _isFetching: false,
  _has_errors: false,
  _data: undefined,
  _error: undefined,
  hasFetched: function () {
    return this._hasFetched;
  },
  isFetching: function () {
    return this._isFetching;
  },
  hasErrors: function () {
    return this._has_errors;
  },
  data: function (param, def = undefined) {
    return param ? lodashGet(this._data, param, def) : this._data;
  },
  error: function () {
    return this._error;
  },
};

const usePromise = (promFunc, { maxAge = 1 } = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const _isFetchingRef = useRef(false);

  useEffect(() => {
    _isFetchingRef.current = state._isFetching;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state._isFetching]);

  const refresh = useCallback(
    (...args) => {
      if (_isFetchingRef.current === true) return;
      dispatch({ type: "startFetching" });
      let __data;
      let __error;
      const memmedFunction = mem(promFunc, {
        cache,
        maxAge,
        cacheKey: (a) => {
          return JSON.stringify(a);
        },
      });

      return memmedFunction(...args)
        .then((payload) => {
          __data = payload;
          return payload;
        })
        .catch((err) => {
          __error = err;
          return Promise.reject(err);
        })
        .finally((_) => {
          if (__error) {
            dispatch({ type: "error", payload: __error });
          } else {
            dispatch({ type: "success", payload: __data });
          }
        });
    },
    [promFunc, maxAge],
  );

  return [{ ...state }, refresh];
};

export default usePromise;
