import axios from "axios";

import config from "../config";
import { store } from "../redux/store";

const axiosHttp = axios.create({
  baseURL: `${config().API_URL}`,
});

axiosHttp.interceptors.request.use(
  (config) => {
    let state = store.getState();

    const token = state?.loginReducer?.user?.authentication_token
      ? state.loginReducer.user.authentication_token
      : null;

    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `${token}` }),
        ...config.headers,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);
export const REQUEST = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
  PURGE: "PURGE",
  LINK: "LINK",
  UNLINK: "UNLINK",
  OPTIONS: "OPTIONS",
};

export const RESPONSE_TYPE = {
  JSON: "json",
  ARRAY_BUFFER: "arraybuffer",
  BLOB: "blog",
  DOCUMENT: "document",
  TEXT: "text",
  STREAM: "stream",
};

/***
 * Top wrapper for API call
 */
export const ApiWrapper = async ({
  method = REQUEST.GET,
  url,
  responseType = RESPONSE_TYPE.JSON,
  data,
}) => {
  try {
    let response;
    switch (method) {
      case REQUEST.GET:
        response = await axiosHttp.get(url, { responseType });
        break;

      case REQUEST.POST:
        response = await axiosHttp.post(url, data, { responseType });
        break;

      case REQUEST.PUT:
        response = await axiosHttp.put(url, data, { responseType });
        break;

      case REQUEST.PATCH:
        response = await axiosHttp.patch(url, data, { responseType });
        break;

      case REQUEST.DELETE:
        response = await axiosHttp.delete(url, data, { responseType });
        break;
      default:
      // just ignore at the moment
    }

    return response?.data;
  } catch (error) {
    // dispatch error
    console.error("Error: ", error);
    return Promise.reject(error);
  }
};
