//Login

export const AdminLogin = {
  url: "admin/users/sign_in",
  method: "POST",
};

export const RegenerateNewToken = {
  url: "/users/regenerate_token",
  method: "GET",
};

export const ForgotPasswordApi = {
  url: "users/password",
  method: "POST",
};

export const ResetPasswordApi = {
  url: "users/password",
  method: "PUT",
};

export const ssoSignInAPI = {
  url: "users/admin/sso_sign_in",
  method: "POST",
};

export const checkEmailAPI = {
  url: "users/admin/email_check",
  method: "GET",
};
