const initCounts = {
  /** Notification counts - start */
  unreadNotificationCount: 0,
  topicsNotificationCount: 0,
  topicSpecificNotificationCount: {},
  chatNotificationCount: 0,
  chatSpecificNotificationCount: {},
  dealNotificationCount: 0,
  webinarNotificationCount: 0,
  videoNotificationCount: 0,
  /** Notification counts - end */
};

let initState = {
  newNotifications: [],
  pastNotifications: [],
  unreadNotifications: [],
  notificationSettings: {},
  unsubscribePush: false,
  unsubscribeEmail: false,
  ...initCounts,
};

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_ALL_NOTIFICATION_COUNTS":
      let topicCount = {};
      action.payload.topic_specific_notifcations_count.forEach(
        ([id, count]) => {
          topicCount[id] = count;
        },
      );

      let chatCount = {};
      action.payload.chat_specific_notifcations_count.forEach(([id, count]) => {
        chatCount[id] = count;
      });

      return {
        ...state,
        unreadNotificationCount: action.payload.unread_notification_count,
        topicsNotificationCount: action.payload.topics_notifcations_count,
        topicSpecificNotificationCount: {
          ...topicCount,
        },
        chatNotificationCount: action.payload.chat_notifcations_count,
        chatSpecificNotificationCount: { ...chatCount },
        dealNotificationCount: action.payload.deal_notifications_count,
        webinarNotificationCount: action.payload.webinar_notifications_count,
        videoNotificationCount: action.payload.video_notifcations_count,
      };
    case "SET_LIVE_TOPIC_NOTIFICATIONS":
      let topicSpecificNotificationCount = {
        ...state.topicSpecificNotificationCount,
      };

      topicSpecificNotificationCount[action.payload.topic_id] =
        action.payload.topic_notifications_count;

      return {
        ...state,
        topicSpecificNotificationCount: {
          ...topicSpecificNotificationCount,
        },
        topicsNotificationCount: action.payload.total_topic_notifications_count,
        unreadNotificationCount: action.payload.total_notifications_count,
      };

    case "SET_LIVE_CHAT_NOTIFICATIONS":
      let chatSpecificNotificationCount = {
        ...state.chatSpecificNotificationCount,
      };

      chatSpecificNotificationCount[action.payload.conversation_id] =
        action.payload.chat_notifications_count;

      return {
        ...state,

        chatSpecificNotificationCount: {
          ...chatSpecificNotificationCount,
        },
        chatNotificationCount: action.payload.total_chat_notifications_count,
        unreadNotificationCount: action.payload.total_notifications_count,
      };

    case "SET_ALL_NOTIFICATIONS":
      return {
        ...state,
        newNotifications: action.payload.new_notifications,
        pastNotifications: action.payload.past_notifications,
        unreadNotifications: action.payload.unread_notifications,
      };
    case "MARK_ALL_NOTIFICATIONS_READ":
      return {
        ...state,
        ...initCounts,
        unreadNotifications: [],
      };
    case "UPDATE_NEW_NOTIFICATIONS":
      return {
        ...state,
        newNotifications: action.payload,
      };
    case "UPDATE_PAST_NOTIFICATIONS":
      return {
        ...state,
        pastNotifications: action.payload,
      };
    case "SET_ALL_NOTIFICATION_SETTINGS":
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
        unsubscribePush:
          action.payload.pushNotifications.length > 0 ? false : true,
        unsubscribeEmail:
          action.payload.emailNotifications.length > 0 ? false : true,
      };
    case "SET_NOTIFICATION_SETTINGS":
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
      };
    case "UNSUBSCRIBE_NOTIFICATION_UPDATE":
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
        unsubscribePush: action.payload.unsubscribePush,
        unsubscribeEmail: action.payload.unsubscribeEmail,
      };
    case "READ_CHAT_SPECIFIC_NOTIFICATION":
      let _state = { ...state };
      _state.chatSpecificNotificationCount[action.payload.conversation_id] = 0;
      _state.chatNotificationCount = action.payload.chat_notifcations_count;
      _state.unreadNotificationCount = action.payload.unread_notification_count;
      return {
        ..._state,
      };
    case "DECREMENT_NOTIFICATION_COUNT":
      if (state.unreadNotificationCount > 0) {
        return {
          ...state,
          unreadNotificationCount: state.unreadNotificationCount - 1,
        };
      } else {
        return { ...state };
      }
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
