//common function to call the api
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import { get, clone } from "underscore";

import config from "../config";
import { clearLocalStorage, toastify } from "../helper/helper";
import { updateUser } from "../redux/action";
import { store } from "../redux/store";
import { RegenerateNewToken } from "./module_wise_api_url/onboarding";

const axios = require("axios");
const qs = require("qs");

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

export const cancelRequest = (
  cancelToken,
  message = "Operation canceled by the user."
) => {
  cancelToken.cancel(message);
};

export const commonApiFN = async (
  requestData,
  { cancelToken = false, regenerateToken = false } = {}
) => {
  if (!regenerateToken) {
    await checkToken();
  }

  const BASE_URL = config().API_URL;
  let state = store.getState();
  const token = get(
    state,
    ["loginReducer", "user", "authentication_token"],
    null
  );
  let headers = {
    ...(requestData.headers ?? {}),
  };

  if (token && token !== null) {
    headers.Authorization = token;
  }

  let promise = new Promise(function (resolve, reject) {
    axios({
      method: requestData.method,
      url: BASE_URL + requestData.url,
      data: requestData.data ? requestData.data : undefined,
      headers,
      params: requestData.params ? requestData.params : undefined,
      cancelToken: cancelToken.token ?? false,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    })
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data.success &&
          requestData.notify
        ) {
          toastify("success", response.data.message);
        } else if (requestData.notify) {
          toastify("error", response.data.message);
        }
        resolve(response.data);
      })
      .catch(function (error) {
        let cancelled = false;
        if (axios.isCancel(error)) {
          cancelled = true;
          // console.log("Request canceled : " + error.message);
        } else {
          if (error && error.response && error.response.status === 401) {
            sessionStorage.clear();
            clearLocalStorage();
            window.location.replace("/");
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.success === false &&
            requestData.notify
          ) {
            if (error.response.data.message)
              toastify("error", error.response.data.message);
            else toastify("error", error.response.statusText);
          }
        }
        resolve(
          error && error.response && error.response.data
            ? { ...error?.response?.data, cancelled }
            : { cancelled }
        );
      });
  });
  return promise;
};

const checkToken = async () => {
  let state = store.getState();

  const authExpire = get(
    state,
    ["loginReducer", "user", "auth_token_expiration"],
    null
  );
  let keepSignedIn = localStorage.getItem("keepSignedIn");
  const authExpiration = moment(authExpire).unix();
  let currDate;
  if (keepSignedIn === "0") {
    currDate = moment().add(5, "minutes").unix();
    keepSignedIn = false;
  } else {
    currDate = moment().add(24, "hours").unix();
    keepSignedIn = true;
  }

  if (currDate >= authExpiration) {
    let requestDetails = clone(RegenerateNewToken);
    if (keepSignedIn) {
      requestDetails.url += "?remember_me=true";
    }

    const response = await commonApiFN(requestDetails, {
      regenerateToken: true,
    });
    if (response.success) {
      const users = response.data;
      Bugsnag.setUser(users.id, users.email, users.name);
      store.dispatch(
        updateUser({
          user: users,
        })
      );
    }
  }
};
