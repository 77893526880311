import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {findWhere} from "underscore";

import routes from "../../routes";

import BackArrow from "../../assets/svg/back-arrow.svg";
import ButtonCustom from "../../components/Button/Button";

const AddFormHeader = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [URL, setURL] = useState("");

  const pathName = window.location.pathname;
  const searchParmas = window.location.search;
  const params = new URLSearchParams(searchParmas);
  const EditId = params.get("id");
  const categoryType = params.get("type");

  const selectedPortal = useSelector(
    (state) => state.loginReducer.selectedPortal
  );

  const moduleDetails = findWhere(routes, {
    path: pathName,
  });
  const onBackClick = () => {
    setRedirect(true);
    if (categoryType === "student-category") {
      if (selectedPortal === "admission") {
        setURL("/admissions/post/student-services");
      } else {
        setURL("/post/student-services");
      }
    } else if (
      selectedPortal === "admission" &&
      moduleDetails?.backUrlAdmission
    ) {
      setURL(moduleDetails?.backUrlAdmission);
    } else {
      setURL(props?.backUrl ? props?.backUrl : moduleDetails?.backUrl);
    }
  };

  return (
    <>
      {redirect ? (
        <Redirect
          to={{
            pathname: URL,
          }}
        />
      ) : (
        ""
      )}
      <ButtonCustom
        type="secondary"
        className="back-div"
        onClick={() => onBackClick()}
      >
        <img src={BackArrow} alt={"back-arrow"} />
        {moduleDetails && moduleDetails.backUrlText
          ? moduleDetails.backUrlText
          : props.backUrlText}{" "}
      </ButtonCustom>
      <div className="text-center form-header-title">
        <h2 className="page-title">
          {EditId ? moduleDetails?.editFormText : moduleDetails?.addFormText}
        </h2>
      </div>
      {props.displaySteps && props.step && props?.totalStepCount > 1  && (
        <h4 className="text-right mb-30 step-title form-header-right-content form-header-step-counter">
          Step {props.step} out of {props.totalStepCount}
        </h4>
      )}
      {moduleDetails?.customOption && (
        <div className="text-right step-title form-header-right-content">
          {moduleDetails.customOption}
        </div>
      )}
    </>
  );
};

export default AddFormHeader;
