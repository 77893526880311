export const PRIMARY_COLOR = "#000485";
export const WHITE_COLOR = "#fff";
export const BLUE_COLOR = "#0030B5";

export const SECONDARY_COLOR = "#f76155";
export const LIGHT_GRAY_COLOR = "#D1DDE5";
export const MEDIUM_GRAY_COLOR = "#BFCED8";
export const GRAY_COLOR = "#607790";
export const GRAY_TEXT_COLOR = "#607790";
export const GRAY_OUTLINE_COLOR = "#D1DDE5";

export const SECONDARY_COLOR_BUTTON_FOCUS = "#F8786E";
export const GRAY_COLOR_BUTTON_FOCUS = "#788BA0";

/** Button Colors */
export const ACTIVE_BTN_HOVER_SECONDARY_COLOR = "#F8786E";

export const ACTIVE_BTN_BLUE_COLOR = "#0030B5";
export const INACTIVE_BTN_BLUE_COLOR = "#7F97DA";
export const ACTIVE_BTN_HOVER_BLUE_COLOR = "#264FC0";

export const ACTIVE_BTN_GRAY_COLOR = "#607790";
export const INACTIVE_BTN_GRAY_COLOR = "#AFBBC7";
export const ACTIVE_BTN_HOVER_GRAY_COLOR = "#788BA0";
export const SECONDARY_BTN_HOVER_COLOR = "#f76155cc";
export const GRAY_BTN_HOVER_COLOR = "#607790cc";

/** ACCESSIBILITY Colors */
export const ACCESSIBILITY_SECONDARY_COLOR = "#E5190B";
export const ACCESSIBILITY_SECONDARY_BTN_HOVER_COLOR = "#E51D0B";
export const ACCESSIBILITY_GRAY_COLOR = "#5A7984";
export const ACCESSIBILITY_GRAY_BTN_HOVER_COLOR = "#3B4A5F";
export const ACCESSIBILITY_LIGHT_GRAY_COLOR = "#6A95A4";
export const ACCESSIBILITY_GRAY_TEXT_COLOR = "#5C728A";
export const ACCESSIBILITY_GRAY_OUTLINE_COLOR = "#7491AA";

export const CHAT_SEND_TEXT_BUTTON = "#F76155";
export const BLUE_COLOR_02 = "#E5EAF7";
export const BLUE_COLOR_04 = "#0270AC";
export const SKY_BLUE_COLOR = "#2AB9EB";
export const BADGE_BACKGROUND_COLOR = "#e5eaf7";
