import styled from "styled-components";

export default styled.div.attrs({ className: "pdf-preview-container" })`
  width: 100%;
  height: 100%;

  .pdf-thumbnail-image {
    width: -webkit-fill-available;
  }

  canvas {
    width: auto !important;
    height: auto !important;
    max-height: ${(props) => (props.height ? `${props.height}px` : "initial")};
    margin: 0 auto;
  }

  .pdf-preview-loader {
    display: flex;
    justify-content: center;
  }

  .pdf-preview-tag {
    position: absolute;
    z-index: 1;
    border: 2px solid #f1f4f7;
    border-radius: 100px;
    color: #000c3d;
    background-color: #ffffff;
    padding: 3px 16px 1px;
    display: flex;
    align-items: center;
    min-height: 29px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .small-tag {
    left: 55px;
    margin-top: 5px;
  }

  .large-tag {
    left: 65px;
    margin-top: 15px;
  }
`;
