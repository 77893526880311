import React, { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Loader from "react-loader-spinner";

import PDFPreviewStyles from "./PDFPreviewStyles";
import { LIGHT_GRAY_COLOR } from "../../styles/variables";

function PDFViewer({
  isImage = false,
  file,
  height,
  id,
  hideTag = false,
  tagSize = "small",
}) {
  const [isLoading, setIsLoading] = useState(isImage ? false : true);

  const onDocumentLoadSuccess = () => {
    setIsLoading(false);
  };

  if (!isImage)
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <PDFPreviewStyles className="pdf-preview-container" height={height}>
      {isImage ? (
        <img src={file} alt="pdf-preview" className="pdf-thumbnail-image" />
      ) : (
        <>
          {!isLoading && !hideTag && (
            <div className={`pdf-preview-tag ${tagSize}-tag`}>PDF</div>
          )}
          <Document
            key={`pdf-doc-${id ?? file}`}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div className="pdf-preview-loader">
                <Loader
                  type="ThreeDots"
                  color={LIGHT_GRAY_COLOR}
                  height={40}
                  width={40}
                />
              </div>
            }
          >
            <Page
              key={`pdf-page-${id ?? file}-1`}
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={<></>}
            />
          </Document>
        </>
      )}
    </PDFPreviewStyles>
  );
}

export default memo(PDFViewer);
