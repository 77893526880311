import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteChat } from "../api/chat";
// import useCabel from "../hooks/useCabel";
import { useChatsList } from "../hooks/useChatsList";
import { getConversationByConversationID } from "../redux/chat/selectors";

// import { extractMessage } from "../utils/common";

export const chatServiceContext = React.createContext({});
export const ChatServiceContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  // const callbackRef = useRef();
  // const audio = new Audio("/notification_version_1.mp3");
  // const id = 29; //TODO: replace it with useId hook
  // const { socketRef, ref: broadcastRef } = useCabel(id);
  const currentConversation = useSelector((state) =>
    getConversationByConversationID(state.chatStore),
  );
  const { selectedPortal } = useSelector((state) => state?.loginReducer);
  const state = useSelector((store) => store.chatStore);
  const { chats, activeConversation, freshChat } = state || {};
  const isFreshChat = freshChat.includes(activeConversation);
  useChatsList();

  /**
   * Check if the user is admin of group
   * @param {*} participants
   * @returns
   */
  // const isAdmin = (participants, getUser = false) => {
  //   const item = participants.find((item) => item.user_id === id);
  //   if (getUser) {
  //     return item;
  //   }
  //   return item.is_group_admin || false;
  // };

  // const playNotification = () => {
  //   const location = window.location.pathname;

  //   if (document.hidden || location !== "/messages") {
  //     audio.play();
  //     return;

  //     // user permission prompt
  //   }
  // };
  /**
   * Update the group preview; details on right side after it is updated
   * @param {*} participants
   */
  // const updateGroupPreview = async (participants = []) => {
  //   try {
  //     const user = participants.find((item) => item.user_id === id);
  //     if (user) {
  //       const { conversation_id } = user || {};
  //       if (conversation_id) {
  //         const response = await fetchConversation(
  //           conversation_id,
  //           {},
  //           "admission"
  //         );
  //         if (response) {
  //           const group_details = response.data?.group_details;
  //           if (group_details) {
  //             dispatch({
  //               type: "updateGroupDetails",
  //               payload: {
  //                 conversationId: conversation_id,
  //                 group_details,
  //               },
  //             });
  //           }
  //         }
  //       }
  //     }
  //   } catch (error) {}
  // };

  /**
   * This calls after the message is sent or received via socket
   * @param {*} id
   * @param {*} content
   */
  // const postProcessMessageAction = (conversationId, message) => {
  //   dispatch({ type: "pushNewMessage", payload: { conversationId, message } });
  //   dispatch({ type: "setIsMessageSending", payload: false });
  //   dispatch({ type: "setIsAttachment", payload: false });
  // };

  /**
   * Calls when message is recieved || sent ; right after receving response from socket and before updating state
   * @param {*} data
   * @returns
   */
  // const processMessageAction = async (data) => {
  //   const {
  //     receiver_id,
  //     sender_id,
  //     receiver_conversation_id,
  //     sender_conversation_id,
  //     type,
  //     group_created,
  //     group_updated,
  //     group_user_details,
  //   } = data || {};

  //   const message = extractMessage(data);

  //   /**
  //    * This is placeholder text for chat preview at left
  //    * @param {*} meta
  //    * @returns
  //    */
  //   const fetchTextMessage = (meta) => {
  //     const { message, shared_file } = meta || {};
  //     if (shared_file) {
  //       if (!message) return "File Attachment";
  //     }
  //     return message || "";
  //   };

  //   if (type === "Group Chat") {
  //     // case when group is either created or updated
  //     if (group_created || group_updated) {
  //       dispatch({ type: "setReloadChats", payload: true });

  //       if (group_created) {
  //         //setGroupTags([]);
  //         if (chats.length === 0) {
  //           dispatch({ type: "setReloadChats", payload: true });
  //         }
  //       }

  //       const admin = isAdmin(group_user_details || []);
  //       if (group_updated) {
  //         updateGroupPreview(group_user_details || []);
  //       }

  //       //if group created by me
  //       if (admin) {
  //         dispatch({ type: "setIsGroupCreating", payload: { status: false } }); //Todo: check if it is needed
  //         try {
  //           if (callbackRef.current) {
  //             callbackRef.current();
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }
  //         const _user = isAdmin(group_user_details || [], true);
  //         const { conversation_id } = _user || {};
  //         if (conversation_id) {
  //           dispatch({
  //             type: "setCurrentConversationId",
  //             payload: conversation_id,
  //           });
  //         }
  //       }
  //       return;
  //     }

  //     //message sent as group
  //     if (sender_id === id && receiver_id === id) {
  //       postProcessMessageAction(sender_conversation_id, message);

  //       dispatch({
  //         type: "updateLatestMessage",
  //         payload: {
  //           latest_message: fetchTextMessage(message),
  //           conversation_id: sender_conversation_id,
  //         },
  //       });

  //       return;
  //     }
  //     // message received
  //     if (id === receiver_id) {
  //       playNotification();
  //       if (activeConversation !== receiver_conversation_id) {
  //         dispatch({ type: "setReloadChats", payload: true });
  //         dispatch({
  //           type: "setTriggerReloadUnreadCountUpdate",
  //           payload: true,
  //         });
  //       }

  //       postProcessMessageAction(receiver_conversation_id, message);
  //     }
  //     return;
  //   }

  //   if (receiver_id === sender_id) return;

  //   /// message sent
  //   if (id === sender_id) {
  //     //if it is the fresh chat
  //     if (isFreshChat) {
  //       const oldConversationId = activeConversation;
  //       const conversation = await fetchConversation(
  //         `${sender_conversation_id}`,
  //         {},
  //         "admission"
  //       );

  //       dispatch({
  //         type: "setConversationInformation",
  //         payload: {
  //           conversation_id: sender_conversation_id,
  //           conversation: conversation.data,
  //         },
  //       });

  //       const preview = await fetchChatsList({
  //         conversation_id: sender_conversation_id,
  //       }); //retrieve preview

  //       if (
  //         preview?.data &&
  //         Object.keys(preview?.data?.conversation).length > 0
  //       ) {
  //         const filtered_chats = chats.filter(
  //           (chat) => chat.conversation_id != oldConversationId
  //         );

  //         const items = [preview.data.conversation, ...filtered_chats];

  //         dispatch({ type: "setChatsList", payload: { chats: items } });
  //       } else {
  //         dispatch({ type: "setReloadChats", payload: true });
  //       }

  //       dispatch({
  //         type: "setCurrentConversationId",
  //         payload: sender_conversation_id,
  //       });
  //       dispatch({
  //         type: "removeUserFromFreshChat",
  //         payload: { id: oldConversationId },
  //       });
  //       dispatch({
  //         type: "removeConversationByConversationID",
  //         payload: {
  //           conversation_id: oldConversationId,
  //         },
  //       });

  //       dispatch({ type: "setIsMessageSending", payload: false });
  //       return;
  //     }

  //     postProcessMessageAction(sender_conversation_id, message);
  //     const text = fetchTextMessage(message);
  //     dispatch({
  //       type: "updateLatestMessage",
  //       payload: {
  //         latest_message: text,
  //         conversation_id: sender_conversation_id,
  //       },
  //     });
  //     return;
  //   }

  //   if (id === receiver_id) {
  //     postProcessMessageAction(receiver_conversation_id, message);

  //     dispatch({ type: "setReloadChats", payload: true });
  //     playNotification();
  //     //setTriggerReloadUnreadCountUpdate(true);
  //   }
  // };

  const onDeleteChat = async (conversationId, callback) => {
    const nextChat = chats.find(
      (item) => item.conversation_id !== conversationId,
    );
    if (isFreshChat) {
      dispatch({
        type: "removeUserFromFreshChat",
        payload: { id: activeConversation },
      });
    } else {
      const response = await deleteChat(conversationId, selectedPortal);
      const { success } = response;
      if (success) {
        dispatch({
          type: "removeConversationByConversationID",
          payload: {
            conversation_id: activeConversation,
          },
        });
        if (chats?.length > 0) {
          dispatch({
            type: "setCurrentConversationId",
            payload: nextChat ? nextChat?.conversation_id : -1,
          });
        }
        // dispatch({ type: "setReloadChats", payload: true });
        callback && callback();
      }
    }

    if (nextChat) {
      dispatch({
        type: "setCurrentConversationId",
        payload: nextChat.conversation_id,
      });
    } else {
      dispatch({ type: "setReloadChats", payload: true });
    }
    callback && callback();
  };

  // const handleGroupCreate = (groupInfo, callback) => {
  //   dispatch({ type: "setIsGroupCreating", payload: { status: true } });
  //   socketRef.current.create(groupInfo);
  //   if (callback) {
  //     callbackRef.current = callback;
  //   }
  // };
  // useEffect(() => {
  //   if (broadcastRef) {
  //     processMessageAction(broadcastRef);
  //   }
  // }, [broadcastRef]);

  return (
    <chatServiceContext.Provider
      value={{
        ...state,
        // socketRef,
        // broadcastRef,
        onDeleteChat,
        currentConversation,
        // handleGroupCreate,
      }}
    >
      {children}
    </chatServiceContext.Provider>
  );
};
