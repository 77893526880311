import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { clone, each } from "underscore";

import { commonApiFN } from "../../../common/api";
import { GetCommunitySuggestions } from "../../../common/module_wise_api_url/engage";
import ContentHeader from "../../../components/Contentheader/index";
import Loader from "../../../components/Loader/Loader";
import PaginationControlled from "../../../components/Pagination/pagination";
import BasicTable from "../../../components/Table/Table";
import { toastify } from "../../../helper/helper";

const CommunitySuggestions = () => {
  const { selectedSchool = null } = useSelector((state) => state.loginReducer);
  const school_id = selectedSchool?.id || null;

  const [showLoader, setShowLoader] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [totalRecords, setTotalrecords] = useState("");
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    total_count: 0,
    total_page: 0,
  });
  const keyField = "id";

  const headers = [
    {
      name: "Community Name",
      sortIcon: false,
    },
    {
      name: "First Name",
      sortIcon: false,
    },
    {
      name: "Last Name",
      sortIcon: false,
    },
    {
      name: "School",
      sortIcon: false,
    },
    {
      name: "Date Added",
      sortIcon: false,
    },
  ];

  const data_keys = [
    { name: "topic_name" },
    { name: "first_name" },
    { name: "last_name" },
    { name: "school_name" },
    { name: "created_at" },
  ];

  useEffect(() => {
    setShowLoader(true);
    getSuggestionsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school_id]);

  const getSuggestionsList = async ({ page, limit } = {}) => {
    const request = clone(GetCommunitySuggestions);

    //Apply Pagination
    request.url =
      request.url +
      `?pagination[page]=${
        page ? page : paginationData.page
      }&pagination[per_page]=${
        (limit && limit === "All") || (!limit && paginationData.limit === "All")
          ? totalRecords
          : limit
          ? limit
          : paginationData.limit
      }`;

    //Apply School
    if (school_id) {
      request.url = request.url + "&school_id[]=" + school_id;
    }

    const response = await commonApiFN(request);

    if (response.success) {
      each(response.data.topics, function (record) {
        record.created_at = moment(record.created_at).format("DD MMM YYYY");
      });

      setSuggestionsList(response.data.topics);

      //Set total count on first data load
      if (totalRecords === "") setTotalrecords(response.data.total_topics);

      setPaginationData((paginationData) => ({
        ...paginationData,
        total_count: response.data.total_topics,
        total_page: response.data.total_pages,
      }));
    } else {
      setSuggestionsList([]);
      if (response?.data?.message) toastify("error", response.data.message);
    }
    setShowLoader(false);
  };

  const changePagination = (page, limit) => {
    setPaginationData({
      ...paginationData,
      page,
      limit,
    });

    getSuggestionsList({ page, limit });
  };

  const disableRecordActions = suggestionsList?.length === 0;

  return (
    <>
      <ContentHeader
        disableRecordActions={disableRecordActions}
        header={"Community Suggestions"}
      />

      {showLoader ? (
        <Loader />
      ) : (
        <>
          <BasicTable
            title={"Community Suggestions"}
            header={headers}
            data_keys={data_keys}
            data={suggestionsList}
            keyField={keyField}
            exportOption={false}
          />
          {suggestionsList && suggestionsList.length !== 0 ? (
            <PaginationControlled
              page={paginationData.page}
              total_page={paginationData.total_page}
              changePaginationFn={changePagination}
              hidePagination={disableRecordActions}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default CommunitySuggestions;
