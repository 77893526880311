import styled from "styled-components";

import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .textbox-label {
    display: flex;
    justify-content: ${(props) => props.lableaignment};
    padding: ${(props) => (props.lableaignment === "left" ? "0 10px" : "0px")};
    margin: 0 0 8px 0;
    font-size: 15px;
    line-height: 15px;
    font-family: "TTCommons-Regular";
    color: ${PRIMARY_COLOR};
  }
  margin-bottom: ${(props) => props.marginBottom}px;

  .MuiFormHelperText-root.Mui-error {
    color: #f7645f;
    display: flex;
    justify-content: center;
    font-family: "TTCommons-Regular";
    font-size: 15px;
  }
  /*
  input[type="password"] {
    ::-webkit-input-placeholder {
      font-size: 18px;
      color: ${PRIMARY_COLOR};
      opacity: 1;
    }
    ::-moz-placeholder {
      font-size: 18px;
      color: ${PRIMARY_COLOR};
      opacity: 1;
    }
    :-ms-input-placeholder {
      font-size: 18px;
      color: ${PRIMARY_COLOR};
      opacity: 1;
    }
    :-moz-placeholder {
      font-size: 18px;
      color: ${PRIMARY_COLOR};
      opacity: 1;
    }
    font-size: 36px;
    opacity: 1;
    color: ${PRIMARY_COLOR};
  }
  */
`;
