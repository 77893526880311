import styled from "styled-components";

// import { PRIMARY_COLOR, GRAY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  button {
    background-color: ${(props) =>
      props.type === "primary" ? "#000485" : "#8094AB"};
    color: #fff;
    text-transform: ${(props) => (props.noCapital ? "none !important" : "")};
  }
`;
